import React from "react";
import Helmet from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";

import { ApolloClient, ApolloProvider, from } from "@apollo/client";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import Routes from "./app/Routes";
import { ccTheme } from "./theme";
import { errorLink, link, cache } from "./utils";
import { CCSnackbar } from "./components";

const client = new ApolloClient({
    link: from([errorLink, link]),
    cache: cache
});

function App() {
    return (
        <ApolloProvider client={client}>
            <div className="App" style={{ width: "100%", height: "100vh" }}>
                <Helmet>
                    <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, width=device-width"
                    />
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
                    <title>
                        Content Creator
                    </title>
                </Helmet>
                <CssBaseline />
                <ThemeProvider theme={ccTheme}>
                    <Router>
                        <Routes />
                    </Router>
                </ThemeProvider>
                <CCSnackbar />
            </div>
        </ApolloProvider>
    );
}

export default App;
