import React, { FunctionComponent } from "react";

import { makeStyles } from "@material-ui/core";

import LinearProgress from "@material-ui/core/LinearProgress";
import create from "zustand";
import { combine } from "zustand/middleware";

const useStyles = makeStyles(() => ({
    linearProgress: {
        position: "fixed",
        width: "100%",
        zIndex: 1300
    }
}));

export const useLoadingBarStore = create(combine({
    loading: false,
}, set => ({
    setLoadingState: (loading: boolean) => set({ loading }),
})));

export const TopLoadingBar: FunctionComponent = () => {
    const classes = useStyles();
    const loading = useLoadingBarStore(state => state.loading);

    if (!loading) {
        return <></>;
    }

    return (
        <LinearProgress
            color={"secondary"}
            classes={{root: classes.linearProgress}}
        />
    );
};
