import { Button, TextField, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { Browser } from "react-kawaii";
import { useHistory } from "react-router";
import { useSnackbarStore } from "../../../../components";
import { useActivateMutation } from "../../../../generated/generated-types";
import { setLoggedIn } from "../../../../utils";
import { useStyles } from "./styles";

interface ActivationFormProps {
    email: string;
    id: string;
}

export const ActivationForm: React.FC<ActivationFormProps> = (props) => {
    const history = useHistory();
    const { email, id } = props;
    const classes = useStyles();

    const [activate, { error }] = useActivateMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: (res) => {
            if (res.activate) {
                setLoggedIn(res.activate._id, res.activate.role);

                history.push("/dashboard");
            }
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);

    return (
        <Formik
            initialValues={{
                email,
                password: "",
                firstName: "",
                lastName: "",
                confirmPassword: "",
            }}
            validate={values => {
                const errors: {
                    password?: string;
                    confirmPassword?: string;
                    firstName?: string;
                    lastName?: string;
                } = {};

                if (!values.password) {
                    errors.password = "Required";
                }

                if (!values.confirmPassword) {
                    errors.confirmPassword = "Required";
                }

                if (!values.password) {
                    errors.password = "Required";
                }


                if (!values.firstName) {
                    errors.firstName = "Required";
                }

                if (!values.lastName) {
                    errors.lastName = "Required";
                }

                if (!values.confirmPassword) {
                    errors.confirmPassword = "Required";
                }

                if (values.password !== values.confirmPassword) {
                    errors.confirmPassword = "Password's don't match";
                }

                return errors;
            }}
            onSubmit={async (values) => {
                await activate({
                    variables: {
                        id,
                        password: values.password,
                        firstName: values.firstName,
                        lastName: values.lastName,
                    },
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <>
                    <div className={classes.hero}>
                        <Browser
                            size={200}
                            mood={error ? "ko" : values.password ? "blissful" : "happy"}
                            color="#61DDBC"
                        />
                    </div>
                    <Typography align={"center"} variant={"h4"} gutterBottom>
                        Activate
                    </Typography>
                    <Typography align={"center"} style={{ marginBottom: 16 }} gutterBottom>
                        Your password must contain at least 8 characters, 1 number and 1 special character
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            autoFocus
                            required
                            autoComplete={"email"}
                            error={!!errors.email && touched.email}
                            helperText={errors.email}
                            label={"Email"}
                            name={"email"}
                            disabled
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <TextField
                            fullWidth
                            required
                            autoComplete={"given-name"}
                            variant={"outlined"}
                            error={!!errors.firstName && touched.firstName}
                            helperText={touched.firstName && errors.firstName}
                            label={"First Name"}
                            name={"firstName"}
                            value={values.firstName}
                            onChange={handleChange}
                            className={classes.formField}
                            onBlur={handleBlur}
                            style={{ marginTop: 32 }}
                        />
                        <TextField
                            fullWidth
                            required
                            autoComplete={"family-name"}
                            variant={"outlined"}
                            error={!!errors.lastName && touched.lastName}
                            helperText={touched.lastName && errors.lastName}
                            label={"Last Name"}
                            name={"lastName"}
                            value={values.lastName}
                            onChange={handleChange}
                            className={classes.formField}
                            onBlur={handleBlur}
                        />
                        <TextField
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={"Password"}
                            type={"password"}
                            name={"password"}
                            className={classes.formField}
                            variant={"outlined"}
                            required
                            value={values.password}
                            error={!!errors.password && touched.password}
                            helperText={touched.password && errors.password}
                        />
                        <TextField
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={"Confirm Password"}
                            type={"password"}
                            name={"confirmPassword"}
                            className={classes.formField}
                            variant={"outlined"}
                            required
                            value={values.confirmPassword}
                            error={!!errors.confirmPassword && touched.confirmPassword}
                            helperText={touched.confirmPassword && errors.confirmPassword}
                        />

                        <div className={classes.submitBtn}>
                            <Button
                                fullWidth
                                variant={"contained"}
                                color={"primary"}
                                disabled={isSubmitting}
                                type={"submit"}
                            >
                                Activate Account
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </Formik>
    );
};
