import marked from "marked";

export function markedDownContent(content: string) {
    const renderer = new marked.Renderer();
    const linkRenderer = renderer.link;
    const imageRenderer = renderer.image;

    renderer.link = (href: string, title: string, text: string) => {
        const html = linkRenderer.call(renderer, href, title, text);
        return html.replace(/^<a /, "<a rel=\"nofollow noreferrer\" target=\"_blank\"");
    };

    renderer.image = (href: string, title: string, text: string) => {
        const image = imageRenderer.call(renderer, href, title, text);
        return image.replace(/^<img /, "<img style=\"max-width: 100%; max-height: 100%\"");
    };
    
    marked.setOptions({
        smartypants: true,
        smartLists: true,
    });

    // Mimic pz api
    content = content.replace(/\\n/g, "\n");

    return marked(content, { renderer });
}
