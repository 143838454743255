import moment from "moment";
import React, { FunctionComponent, useCallback } from "react";

import {
    Button, Card, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader
} from "@material-ui/core";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

import { Header, Page, useSnackbarStore } from "../../components";
import {
    RequestActionType,
    RequestType, useActionRequestMutation, useAllRequestsQuery, useParenthubArticleQuery
} from "../../generated/generated-types";
import { ApproveButton } from "./components/ApproveButton";

export const ReviewRequestsScreen: FunctionComponent = () => {
    return (
        <>
            <Page
                header={
                    <Header title={"Review Requests"} />
                }
            >
                <AllPendingRequestsOverview />
            </Page>
        </>
    );
};

const AllPendingRequestsOverview = () => {
    const { data, refetch: refetchAllRequests } = useAllRequestsQuery();

    return (
        <Card style={{ marginBottom: 32 }}>
            <List
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        All Requests
                    </ListSubheader>
                }
            >
                {data?.getAllRequests.map((d) => {
                    const type = d.type === RequestType.Deletearticle
                        ? "Delete Article"
                        : d.type;
                    
                    return (
                        <RequestItem
                            key={d._id}
                            requestId={d._id}
                            metaId={d.metaId}
                            refetchAllRequests={refetchAllRequests}
                            type={type}
                            createdAt={d.createdAt}
                            author={`${d.createdByUser.firstName} ${d.createdByUser.lastName}`}
                        />
                    );
                })
                }
            </List>
        </Card>
    );
};

const RequestItem: React.FC<{refetchAllRequests(): void, requestId: string, metaId: string, type: string, createdAt: string, author: string}> = (props) => {
    const { metaId, requestId, refetchAllRequests, type, createdAt, author } = props;

    const { data: articleData } = useParenthubArticleQuery({
        variables: {
            id: metaId
        }
    });
    
    const [request] = useActionRequestMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: async () => {
            await refetchAllRequests();
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);

    const approve = useCallback(
        async () => {
            await request({variables: {id: requestId, action: RequestActionType.Approve}});
        },
        [request, requestId],
    );

    const decline = useCallback(
        async () => {
            await request({variables: {id: requestId, action: RequestActionType.Decline}});
        },
        [request, requestId],
    );

    return (
        <ListItem
            dense
            key={requestId}
        >
            <DeleteSweepIcon style={{marginRight: 16, color: "rgba(0,0,0,0.54)"}} />
            <ListItemText
                primary={type}
                secondary={`${articleData?.article.title} - submitted by ${author}, ${moment(createdAt).fromNow()}`}
            />
            <ListItemSecondaryAction>
                <Button
                    variant={"text"}
                    color={"primary"}
                    style={{marginRight: 8}}
                    onClick={decline}
                >
                    Reject
                </Button>
                <ApproveButton approve={approve} />
            </ListItemSecondaryAction>
        </ListItem>
    );
};
