import React, { FunctionComponent } from "react";
import { Redirect } from "react-router";
import isLoggedIn from "../utils/isLoggedIn";

export const HomeRoute: FunctionComponent = () => {
    const loggedIn = isLoggedIn();

    if (loggedIn) {
        return <Redirect to={"/dashboard"} />;
    } else {
        return <Redirect to={"/login"} />;
    }
};
