import { Button, Chip, Paper, TextField, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { useLoadingBarStore, useSnackbarStore } from "../../../../components";
import { Tag, useCreateDraftMutation, useDashboardQuery, useSubmissionsQuery } from "../../../../generated/generated-types";
import getRole from "../../../../utils/getRole";
import { useStyles } from "./styles";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const draftToHtml = require("draftjs-to-html");
const showdown  = require('showdown');

interface AddArticleFormProps {
    selectedHubId: string;
    tags: Tag[];
}

const converter = new showdown.Converter();

export const AddArticleForm: React.FC<AddArticleFormProps> = (props) => {
    const history = useHistory();
    const { selectedHubId, tags } = props;
    const classes = useStyles();

    const { refetch: refetchMySubmissions } = useSubmissionsQuery();
    const { refetch: refetchRecentActivity } = useDashboardQuery();

    const [createDraft, { loading: createDraftLoading }] = useCreateDraftMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);  
        },
        onCompleted: async () => {
            await refetchMySubmissions();
            await refetchRecentActivity();

            history.push("/parenthub/manage-submissions");
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(createDraftLoading);
    }, [createDraftLoading, setLoadingStore]);

    return (
        <Formik
            initialValues={{
                title: "",
                description: "",
                content: "",
                tags: [] as string[],
                image: undefined,
                imagePreview: undefined,
            }}
            validate={values => {
                const errors: {
                    title?: string;
                    description?: string;
                    content?: string;
                } = {};

                if (!values.title) {
                    errors.title = "Required";
                }

                if (!values.description) {
                    errors.description = "Required";
                }

                if (!values.content) {
                    errors.content = "Required";
                }

                return errors;
            }}
            onSubmit={async (values) => {
                if (!selectedHubId) {
                    return;
                }

                await createDraft({
                    variables: {
                        hub: selectedHubId,
                        title: values.title,
                        description: values.description,
                        file: values.image,
                        content: values.content,
                        tags: values.tags.length === 0 ? undefined : values.tags
                    },
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                submitForm,
                isSubmitting,
            }) => {
                return (
                    <>
                        <Typography style={{ marginTop: 16, color: "#757575" }}>
                            Upload Header Image*
                        </Typography>
                        <Button>
                            <input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                onChange={(e) => {
                                    if (e?.target?.files) {
                                        const file = e?.target?.files[0];
                                        setFieldValue("image", file);
                                        setFieldValue("imagePreview", URL.createObjectURL(file));
                                    }
                                }} 
                            />
                        </Button>
                        <div style={{width: "100%"}}>
                            {values.imagePreview && <img style={{maxHeight: 300, objectFit: "cover"}} src={values.imagePreview} />}
                        </div>
                        <TextField
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={"Title"}
                            name={"title"}
                            inputProps={{ maxLength: 40 }}
                            style={{ marginTop: 8 }}
                            required
                            value={values.title}
                            error={!!errors.title && touched.title}
                            helperText={"The title of the article, shown on the front page of the hub. Maximum 40 characters."}
                        />
                        <TextField
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={"Description"}
                            name={"description"}
                            inputProps={{ maxLength: 80 }}
                            style={{ marginTop: 16 }}
                            required
                            value={values.description}
                            error={!!errors.description && touched.description}
                            helperText={"A short 1 sentence subtitle, shown on the front page of the hub. Maximum 80 characters."}
                        />
                        <Typography style={{ marginTop: 32, marginBottom: 4, color: "#757575" }}>
                            Tags
                        </Typography>
                        {tags.map((tag) => {
                            const selected = values.tags.includes(tag._id);

                            return (
                                <Chip
                                    key={tag.name}
                                    label={tag.name}
                                    color={selected ? "primary" : undefined}
                                    clickable
                                    variant={"outlined"}
                                    onClick={() => setFieldValue("tags", selected
                                        ? values.tags.filter((t) => t !== tag._id)
                                        : [...values.tags, tag._id])
                                    }
                                    style={{ marginRight: 4, marginBottom: 4 }}
                                />
                            );
                        })}
                        <Typography style={{ marginTop: 32, color: "#757575" }}>
                            Content* (Max 5000 characters)
                        </Typography>
                        <Paper>
                            <Editor
                                onContentStateChange={(raw) => {
                                    const html = draftToHtml(raw);
                                    const markdown = converter.makeMarkdown(html)

                                    const markdownWithParagraphs = markdown.replace(/^\s*\n/gm, "\\n");

                                    setFieldValue("content", markdownWithParagraphs, true);
                                }}
                                editorClassName={classes.content}
                            />
                        </Paper>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            style={{ marginTop: 32, marginBottom: 32 }}
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            Submit for Review
                        </Button>
                    </>
                );
            }}
        </Formik>
    );
};
