import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(0),
        width: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    dialog: {
        minWidth: theme.breakpoints.values.sm,
    }
}));