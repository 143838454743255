import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  _id: Scalars['ID'];
  /** Number of times the user has failed to login */
  badLoginAttempts: Scalars['Float'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  notificationSettings: NotificationSettings;
  /** Expires after 30 days. An account without a password either hasn't been activated, or hasn't renewed it's password. */
  password: Scalars['String'];
  /** If resetting password, can't reuse the same old password */
  passwordHistory?: Maybe<Array<Scalars['String']>>;
  role: Scalars['String'];
  statistics: Statistics;
  status: AccountStatus;
};

/** The status of the account */
export enum AccountStatus {
  Active = 'ACTIVE',
  Locked = 'LOCKED',
  Pending = 'PENDING'
}

export type ActivationCode = {
  __typename?: 'ActivationCode';
  _id: Scalars['ID'];
  accountId: Scalars['ID'];
  /** Creation timestamp. TTL for 30 days after initial creation */
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
};

export type Article = {
  __typename?: 'Article';
  _id: Scalars['ID'];
  canMakeDeleteArticleRequest: Scalars['Boolean'];
  content: Scalars['String'];
  description: Scalars['String'];
  hub: Scalars['ID'];
  image: Scalars['String'];
  tags: Array<Scalars['ID']>;
  title: Scalars['String'];
};

/** The status of the article draft */
export enum ArticleStatus {
  Needschanges = 'NEEDSCHANGES',
  Submitted = 'SUBMITTED'
}

export type ArticleTrace = {
  __typename?: 'ArticleTrace';
  /** True if email notifications are enabled */
  articleId: Scalars['ID'];
  /** Date traced */
  createdAt: Scalars['DateTime'];
};

export type Comment = {
  __typename?: 'Comment';
  _id: Scalars['ID'];
  /** Creation timestamp */
  createdAt: Scalars['DateTime'];
  createdByUser: CreatedByUser;
  /** True if a comment should be marked as important, i.e raised from a flag */
  isImportant: Scalars['Boolean'];
  message: Scalars['String'];
};

export type CreatedByUser = {
  __typename?: 'CreatedByUser';
  _id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type Draft = {
  __typename?: 'Draft';
  _id: Scalars['ID'];
  comments?: Maybe<Array<Comment>>;
  content: Scalars['String'];
  /** Creation timestamp */
  createdAt: Scalars['DateTime'];
  createdByUser: CreatedByUser;
  description: Scalars['String'];
  hub: Scalars['ID'];
  image: Scalars['String'];
  status: ArticleStatus;
  tags?: Maybe<Array<Scalars['ID']>>;
  title: Scalars['String'];
};

export type FlaggedDraftNotification = {
  __typename?: 'FlaggedDraftNotification';
  date: Scalars['DateTime'];
  draft: Draft;
};

export type Hub = {
  __typename?: 'Hub';
  _id: Scalars['ID'];
  image: Scalars['String'];
  name: Scalars['String'];
  ordinal: Scalars['Float'];
};

export type Log = {
  __typename?: 'Log';
  _id: Scalars['ID'];
  /** Creation timestamp */
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  involvedUsers: Array<Scalars['ID']>;
  subDescription: Scalars['String'];
  type: LogType;
};

/** The type of log */
export enum LogType {
  Draftsubmitted = 'DRAFTSUBMITTED',
  Published = 'PUBLISHED'
}

export type Mutation = {
  __typename?: 'Mutation';
  actionRequest: Request;
  activate: Account;
  commentDraft: Comment;
  createAccount: Account;
  createDraft: Draft;
  deleteAccount: Account;
  deleteDraft: Draft;
  deleteNotification: Notification;
  editAccount: Account;
  editDraft: Draft;
  flagDraft: Scalars['Boolean'];
  login?: Maybe<Account>;
  makeDeleteArticleRequest: Request;
  publishDraft: Article;
  readNotification: Notification;
  reset: Account;
  resolveDraft: Scalars['Boolean'];
  sendResetCode: Scalars['Boolean'];
  unflagDraft: Scalars['Boolean'];
  updateNotificationSettings: Account;
  updatePassword: Account;
};


export type MutationActionRequestArgs = {
  action: RequestActionType;
  id: Scalars['String'];
};


export type MutationActivateArgs = {
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCommentDraftArgs = {
  id: Scalars['String'];
  isImportant?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  email: Scalars['String'];
  role: Scalars['String'];
};


export type MutationCreateDraftArgs = {
  content: Scalars['String'];
  description: Scalars['String'];
  file: Scalars['Upload'];
  hub: Scalars['ID'];
  tags?: Maybe<Array<Scalars['ID']>>;
  title: Scalars['String'];
};


export type MutationDeleteAccountArgs = {
  email: Scalars['String'];
};


export type MutationDeleteDraftArgs = {
  id: Scalars['String'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['String'];
};


export type MutationEditAccountArgs = {
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<RoleEnum>;
};


export type MutationEditDraftArgs = {
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  hub?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  tags?: Maybe<Array<Scalars['ID']>>;
  title?: Maybe<Scalars['String']>;
};


export type MutationFlagDraftArgs = {
  id: Scalars['String'];
  message: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationMakeDeleteArticleRequestArgs = {
  id: Scalars['String'];
};


export type MutationPublishDraftArgs = {
  id: Scalars['String'];
};


export type MutationReadNotificationArgs = {
  id: Scalars['String'];
};


export type MutationResetArgs = {
  id: Scalars['String'];
  password: Scalars['String'];
};


export type MutationResolveDraftArgs = {
  id: Scalars['String'];
};


export type MutationSendResetCodeArgs = {
  email: Scalars['String'];
};


export type MutationUnflagDraftArgs = {
  id: Scalars['String'];
};


export type MutationUpdateNotificationSettingsArgs = {
  enabled: Scalars['Boolean'];
  type: NotificationSettingsType;
};


export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type NewDraftNotification = {
  __typename?: 'NewDraftNotification';
  date: Scalars['DateTime'];
  draft: Draft;
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['ID'];
  /** Creation timestamp */
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  /** Meta data to be used for linking the notification, usually an ID */
  meta: Scalars['String'];
  read: Scalars['Boolean'];
  type: NotificationType;
  user: Scalars['ID'];
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  /** True if email notifications are enabled */
  email: Scalars['Boolean'];
  /** True if in app notifications are enabled */
  inApp: Scalars['Boolean'];
  /** True if real time overlay notifications are enabled */
  realTime: Scalars['Boolean'];
};

/** The type of notification setting enabled */
export enum NotificationSettingsType {
  Email = 'EMAIL',
  Inapp = 'INAPP',
  Realtime = 'REALTIME'
}

/** The type of notification */
export enum NotificationType {
  Draftsubmitted = 'DRAFTSUBMITTED',
  Flagged = 'FLAGGED',
  Published = 'PUBLISHED'
}

export type PublishedArticleNotification = {
  __typename?: 'PublishedArticleNotification';
  article: Article;
  date: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  accessToken?: Maybe<Account>;
  accountById: Account;
  accounts?: Maybe<Array<Account>>;
  activationDetails: ActivationCode;
  allDrafts?: Maybe<Array<Draft>>;
  article: Article;
  articleCount: Scalars['Float'];
  articles: Array<Article>;
  contributionsCount: Scalars['Float'];
  draft?: Maybe<Draft>;
  flaggedDraftsCount: Scalars['Float'];
  getAllRequests: Array<Request>;
  getMyPastRequests: Array<Request>;
  getMyPendingRequests: Array<Request>;
  hubs: Array<Hub>;
  myDrafts?: Maybe<Array<Draft>>;
  notifications: Array<Notification>;
  publishedCount: Scalars['Float'];
  recentActivity: Array<Log>;
  resetDetails: ResetPasswordCode;
  roles?: Maybe<Array<Role>>;
  tags: Array<Tag>;
};


export type QueryAccountByIdArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryActivationDetailsArgs = {
  id: Scalars['String'];
};


export type QueryArticleArgs = {
  articleId: Scalars['String'];
};


export type QueryArticlesArgs = {
  hubId: Scalars['String'];
};


export type QueryContributionsCountArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
};


export type QueryDraftArgs = {
  id: Scalars['String'];
};


export type QueryNotificationsArgs = {
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};


export type QueryPublishedCountArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
};


export type QueryResetDetailsArgs = {
  id: Scalars['String'];
};

export type Request = {
  __typename?: 'Request';
  _id: Scalars['ID'];
  /** Creation timestamp */
  createdAt: Scalars['DateTime'];
  createdByUser: CreatedByUser;
  /** ID of the request in question. Could be article ID, hub ID etc.. */
  metaId: Scalars['ID'];
  /** Status of the request */
  status: RequestStatus;
  type: RequestType;
};

/** The action to take on the request */
export enum RequestActionType {
  Approve = 'APPROVE',
  Decline = 'DECLINE'
}

/** The status of the request */
export enum RequestStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

/** The type of request */
export enum RequestType {
  Addhub = 'ADDHUB',
  Deletearticle = 'DELETEARTICLE',
  Deletehub = 'DELETEHUB',
  Movehub = 'MOVEHUB'
}

export type ResetPasswordCode = {
  __typename?: 'ResetPasswordCode';
  _id: Scalars['ID'];
  accountId: Scalars['ID'];
  /** Creation timestamp. TTL for 30 days after initial creation */
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  _id: Scalars['ID'];
  role: Scalars['String'];
};

/** The type of role */
export enum RoleEnum {
  Admin = 'ADMIN',
  Creator = 'CREATOR',
  Qa = 'QA'
}

export type Statistics = {
  __typename?: 'Statistics';
  /** True if in app notifications are enabled */
  contributed: Array<ArticleTrace>;
  /** True if email notifications are enabled */
  published: Array<ArticleTrace>;
};

export type Subscription = {
  __typename?: 'Subscription';
  flaggedDraft: FlaggedDraftNotification;
  newDraft: NewDraftNotification;
  publishedArticle: PublishedArticleNotification;
};

export type Tag = {
  __typename?: 'Tag';
  _id: Scalars['ID'];
  name: Scalars['String'];
};


export type ActionRequestMutationVariables = Exact<{
  id: Scalars['String'];
  action: RequestActionType;
}>;


export type ActionRequestMutation = (
  { __typename?: 'Mutation' }
  & { actionRequest: (
    { __typename?: 'Request' }
    & Pick<Request, '_id'>
  ) }
);

export type ActivateMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  id: Scalars['String'];
}>;


export type ActivateMutation = (
  { __typename?: 'Mutation' }
  & { activate: (
    { __typename?: 'Account' }
    & Pick<Account, '_id' | 'role'>
  ) }
);

export type CommentDraftMutationVariables = Exact<{
  id: Scalars['String'];
  message: Scalars['String'];
}>;


export type CommentDraftMutation = (
  { __typename?: 'Mutation' }
  & { commentDraft: (
    { __typename?: 'Comment' }
    & Pick<Comment, '_id'>
  ) }
);

export type CreateAccountMutationVariables = Exact<{
  email: Scalars['String'];
  role: Scalars['String'];
}>;


export type CreateAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'email'>
  ) }
);

export type CreateDraftMutationVariables = Exact<{
  hub: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  file: Scalars['Upload'];
  tags?: Maybe<Array<Scalars['ID']>>;
  content: Scalars['String'];
}>;


export type CreateDraftMutation = (
  { __typename?: 'Mutation' }
  & { createDraft: (
    { __typename?: 'Draft' }
    & Pick<Draft, '_id'>
  ) }
);

export type DeleteAccountMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type DeleteAccountMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccount: (
    { __typename?: 'Account' }
    & Pick<Account, '_id'>
  ) }
);

export type DeleteDraftMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDraftMutation = (
  { __typename?: 'Mutation' }
  & { deleteDraft: (
    { __typename?: 'Draft' }
    & Pick<Draft, '_id'>
  ) }
);

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteNotification: (
    { __typename?: 'Notification' }
    & Pick<Notification, '_id'>
  ) }
);

export type EditAccountMutationVariables = Exact<{
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<RoleEnum>;
}>;


export type EditAccountMutation = (
  { __typename?: 'Mutation' }
  & { editAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'email'>
  ) }
);

export type EditDraftMutationVariables = Exact<{
  hub?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  tags?: Maybe<Array<Scalars['ID']>>;
  content?: Maybe<Scalars['String']>;
}>;


export type EditDraftMutation = (
  { __typename?: 'Mutation' }
  & { editDraft: (
    { __typename?: 'Draft' }
    & Pick<Draft, '_id'>
  ) }
);

export type FlagDraftMutationVariables = Exact<{
  id: Scalars['String'];
  message: Scalars['String'];
}>;


export type FlagDraftMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'flagDraft'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'role' | '_id'>
  )> }
);

export type MakeDeleteArticleRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type MakeDeleteArticleRequestMutation = (
  { __typename?: 'Mutation' }
  & { makeDeleteArticleRequest: (
    { __typename?: 'Request' }
    & Pick<Request, '_id'>
  ) }
);

export type PublishDraftMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type PublishDraftMutation = (
  { __typename?: 'Mutation' }
  & { publishDraft: (
    { __typename?: 'Article' }
    & Pick<Article, '_id' | 'title'>
  ) }
);

export type ReadNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & { readNotification: (
    { __typename?: 'Notification' }
    & Pick<Notification, '_id'>
  ) }
);

export type ResetMutationVariables = Exact<{
  id: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetMutation = (
  { __typename?: 'Mutation' }
  & { reset: (
    { __typename?: 'Account' }
    & Pick<Account, '_id' | 'role'>
  ) }
);

export type ResolveDraftMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ResolveDraftMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resolveDraft'>
);

export type SendResetCodeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendResetCode'>
);

export type UnflagDraftMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type UnflagDraftMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unflagDraft'>
);

export type UpdateNotificationSettingsMutationVariables = Exact<{
  type: NotificationSettingsType;
  enabled: Scalars['Boolean'];
}>;


export type UpdateNotificationSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationSettings: (
    { __typename?: 'Account' }
    & Pick<Account, '_id'>
  ) }
);

export type UpdatePasswordMutationVariables = Exact<{
  password: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword: (
    { __typename?: 'Account' }
    & Pick<Account, '_id'>
  ) }
);

export type AccessTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type AccessTokenQuery = (
  { __typename?: 'Query' }
  & { accessToken?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, '_id' | 'role'>
  )> }
);

export type AccountQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type AccountQuery = (
  { __typename?: 'Query' }
  & { accountById: (
    { __typename?: 'Account' }
    & Pick<Account, '_id' | 'email' | 'role' | 'firstName' | 'lastName' | 'status'>
  ) }
);

export type AccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<Array<(
    { __typename?: 'Account' }
    & Pick<Account, '_id' | 'email' | 'role' | 'firstName' | 'lastName' | 'status' | 'passwordHistory'>
  )>> }
);

export type ActivationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ActivationQuery = (
  { __typename?: 'Query' }
  & { activationDetails: (
    { __typename?: 'ActivationCode' }
    & Pick<ActivationCode, 'email'>
  ) }
);

export type AllRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllRequestsQuery = (
  { __typename?: 'Query' }
  & { getAllRequests: Array<(
    { __typename?: 'Request' }
    & Pick<Request, '_id' | 'metaId' | 'type' | 'createdAt'>
    & { createdByUser: (
      { __typename?: 'CreatedByUser' }
      & Pick<CreatedByUser, 'firstName' | 'lastName'>
    ) }
  )> }
);

export type AllSubmissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllSubmissionsQuery = (
  { __typename?: 'Query' }
  & { hubs: Array<(
    { __typename?: 'Hub' }
    & Pick<Hub, '_id' | 'name' | 'image'>
  )>, allDrafts?: Maybe<Array<(
    { __typename?: 'Draft' }
    & SubmissionDraftFragment
  )>> }
);

export type ArticleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ArticleQuery = (
  { __typename?: 'Query' }
  & { hubs: Array<(
    { __typename?: 'Hub' }
    & Pick<Hub, '_id' | 'name' | 'image'>
  )>, draft?: Maybe<(
    { __typename?: 'Draft' }
    & Pick<Draft, '_id' | 'hub' | 'title' | 'description' | 'image' | 'content' | 'status' | 'tags'>
    & { createdByUser: (
      { __typename?: 'CreatedByUser' }
      & Pick<CreatedByUser, '_id' | 'firstName' | 'lastName'>
    ), comments?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'message' | 'isImportant' | 'createdAt' | '_id'>
      & { createdByUser: (
        { __typename?: 'CreatedByUser' }
        & Pick<CreatedByUser, '_id' | 'firstName' | 'lastName'>
      ) }
    )>> }
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, '_id' | 'name'>
  )> }
);

export type DashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'articleCount' | 'contributionsCount' | 'publishedCount' | 'flaggedDraftsCount'>
  & { recentActivity: Array<(
    { __typename?: 'Log' }
    & Pick<Log, 'type' | 'subDescription' | 'description' | 'createdAt' | '_id'>
  )> }
);

export type HubsTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type HubsTagsQuery = (
  { __typename?: 'Query' }
  & { hubs: Array<(
    { __typename?: 'Hub' }
    & Pick<Hub, '_id' | 'name' | 'ordinal' | 'image'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, '_id' | 'name'>
  )> }
);

export type MyRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyRequestsQuery = (
  { __typename?: 'Query' }
  & { getMyPendingRequests: Array<(
    { __typename?: 'Request' }
    & Pick<Request, '_id' | 'metaId' | 'type' | 'createdAt'>
  )>, getMyPastRequests: Array<(
    { __typename?: 'Request' }
    & Pick<Request, '_id' | 'metaId' | 'type' | 'createdAt'>
  )> }
);

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, '_id' | 'type' | 'description' | 'meta' | 'createdAt' | 'read'>
  )> }
);

export type ParenthubArticleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ParenthubArticleQuery = (
  { __typename?: 'Query' }
  & { article: (
    { __typename?: 'Article' }
    & Pick<Article, '_id' | 'title' | 'image' | 'description' | 'content' | 'canMakeDeleteArticleRequest'>
  ) }
);

export type ParenthubArticlesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ParenthubArticlesQuery = (
  { __typename?: 'Query' }
  & { articles: Array<(
    { __typename?: 'Article' }
    & Pick<Article, '_id' | 'title' | 'image' | 'description'>
  )> }
);

export type ProfileQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  lastMonth?: Maybe<Scalars['DateTime']>;
}>;


export type ProfileQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contributionsCount' | 'publishedCount'>
  & { monthContributionsCount: Query['contributionsCount'], monthPublishedCount: Query['publishedCount'] }
  & { accountById: (
    { __typename?: 'Account' }
    & Pick<Account, '_id' | 'email' | 'role' | 'firstName' | 'lastName' | 'status'>
    & { notificationSettings: (
      { __typename?: 'NotificationSettings' }
      & Pick<NotificationSettings, 'email' | 'inApp' | 'realTime'>
    ) }
  ) }
);

export type ResetDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ResetDetailsQuery = (
  { __typename?: 'Query' }
  & { resetDetails: (
    { __typename?: 'ResetPasswordCode' }
    & Pick<ResetPasswordCode, 'email'>
  ) }
);

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles?: Maybe<Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'role'>
  )>> }
);

export type SubmissionDraftFragment = (
  { __typename?: 'Draft' }
  & Pick<Draft, '_id' | 'hub' | 'title' | 'description' | 'status'>
);

export type SubmissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SubmissionsQuery = (
  { __typename?: 'Query' }
  & { hubs: Array<(
    { __typename?: 'Hub' }
    & Pick<Hub, '_id' | 'name' | 'image'>
  )>, myDrafts?: Maybe<Array<(
    { __typename?: 'Draft' }
    & SubmissionDraftFragment
  )>> }
);

export type FlaggedDraftSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type FlaggedDraftSubscription = (
  { __typename?: 'Subscription' }
  & { flaggedDraft: (
    { __typename?: 'FlaggedDraftNotification' }
    & Pick<FlaggedDraftNotification, 'date'>
    & { draft: (
      { __typename?: 'Draft' }
      & Pick<Draft, '_id'>
    ) }
  ) }
);

export type NewDraftSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewDraftSubscription = (
  { __typename?: 'Subscription' }
  & { newDraft: (
    { __typename?: 'NewDraftNotification' }
    & Pick<NewDraftNotification, 'date'>
    & { draft: (
      { __typename?: 'Draft' }
      & Pick<Draft, '_id'>
    ) }
  ) }
);

export type PublishedArticleSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PublishedArticleSubscription = (
  { __typename?: 'Subscription' }
  & { publishedArticle: (
    { __typename?: 'PublishedArticleNotification' }
    & Pick<PublishedArticleNotification, 'date'>
    & { article: (
      { __typename?: 'Article' }
      & Pick<Article, '_id'>
    ) }
  ) }
);

export const SubmissionDraftFragmentDoc = gql`
    fragment SubmissionDraft on Draft {
  _id
  hub
  title
  description
  status
}
    `;
export const ActionRequestDocument = gql`
    mutation actionRequest($id: String!, $action: RequestActionType!) {
  actionRequest(id: $id, action: $action) {
    _id
  }
}
    `;
export type ActionRequestMutationFn = Apollo.MutationFunction<ActionRequestMutation, ActionRequestMutationVariables>;

/**
 * __useActionRequestMutation__
 *
 * To run a mutation, you first call `useActionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionRequestMutation, { data, loading, error }] = useActionRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useActionRequestMutation(baseOptions?: Apollo.MutationHookOptions<ActionRequestMutation, ActionRequestMutationVariables>) {
        return Apollo.useMutation<ActionRequestMutation, ActionRequestMutationVariables>(ActionRequestDocument, baseOptions);
      }
export type ActionRequestMutationHookResult = ReturnType<typeof useActionRequestMutation>;
export type ActionRequestMutationResult = Apollo.MutationResult<ActionRequestMutation>;
export type ActionRequestMutationOptions = Apollo.BaseMutationOptions<ActionRequestMutation, ActionRequestMutationVariables>;
export const ActivateDocument = gql`
    mutation Activate($firstName: String!, $lastName: String!, $password: String!, $id: String!) {
  activate(firstName: $firstName, lastName: $lastName, id: $id, password: $password) {
    _id
    role
  }
}
    `;
export type ActivateMutationFn = Apollo.MutationFunction<ActivateMutation, ActivateMutationVariables>;

/**
 * __useActivateMutation__
 *
 * To run a mutation, you first call `useActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateMutation, { data, loading, error }] = useActivateMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateMutation(baseOptions?: Apollo.MutationHookOptions<ActivateMutation, ActivateMutationVariables>) {
        return Apollo.useMutation<ActivateMutation, ActivateMutationVariables>(ActivateDocument, baseOptions);
      }
export type ActivateMutationHookResult = ReturnType<typeof useActivateMutation>;
export type ActivateMutationResult = Apollo.MutationResult<ActivateMutation>;
export type ActivateMutationOptions = Apollo.BaseMutationOptions<ActivateMutation, ActivateMutationVariables>;
export const CommentDraftDocument = gql`
    mutation CommentDraft($id: String!, $message: String!) {
  commentDraft(id: $id, message: $message) {
    _id
  }
}
    `;
export type CommentDraftMutationFn = Apollo.MutationFunction<CommentDraftMutation, CommentDraftMutationVariables>;

/**
 * __useCommentDraftMutation__
 *
 * To run a mutation, you first call `useCommentDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentDraftMutation, { data, loading, error }] = useCommentDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCommentDraftMutation(baseOptions?: Apollo.MutationHookOptions<CommentDraftMutation, CommentDraftMutationVariables>) {
        return Apollo.useMutation<CommentDraftMutation, CommentDraftMutationVariables>(CommentDraftDocument, baseOptions);
      }
export type CommentDraftMutationHookResult = ReturnType<typeof useCommentDraftMutation>;
export type CommentDraftMutationResult = Apollo.MutationResult<CommentDraftMutation>;
export type CommentDraftMutationOptions = Apollo.BaseMutationOptions<CommentDraftMutation, CommentDraftMutationVariables>;
export const CreateAccountDocument = gql`
    mutation CreateAccount($email: String!, $role: String!) {
  createAccount(email: $email, role: $role) {
    email
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, baseOptions);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const CreateDraftDocument = gql`
    mutation CreateDraft($hub: ID!, $title: String!, $description: String!, $file: Upload!, $tags: [ID!], $content: String!) {
  createDraft(hub: $hub, title: $title, file: $file, tags: $tags, content: $content, description: $description) {
    _id
  }
}
    `;
export type CreateDraftMutationFn = Apollo.MutationFunction<CreateDraftMutation, CreateDraftMutationVariables>;

/**
 * __useCreateDraftMutation__
 *
 * To run a mutation, you first call `useCreateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftMutation, { data, loading, error }] = useCreateDraftMutation({
 *   variables: {
 *      hub: // value for 'hub'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      file: // value for 'file'
 *      tags: // value for 'tags'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateDraftMutation(baseOptions?: Apollo.MutationHookOptions<CreateDraftMutation, CreateDraftMutationVariables>) {
        return Apollo.useMutation<CreateDraftMutation, CreateDraftMutationVariables>(CreateDraftDocument, baseOptions);
      }
export type CreateDraftMutationHookResult = ReturnType<typeof useCreateDraftMutation>;
export type CreateDraftMutationResult = Apollo.MutationResult<CreateDraftMutation>;
export type CreateDraftMutationOptions = Apollo.BaseMutationOptions<CreateDraftMutation, CreateDraftMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($email: String!) {
  deleteAccount(email: $email) {
    _id
  }
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, baseOptions);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const DeleteDraftDocument = gql`
    mutation DeleteDraft($id: String!) {
  deleteDraft(id: $id) {
    _id
  }
}
    `;
export type DeleteDraftMutationFn = Apollo.MutationFunction<DeleteDraftMutation, DeleteDraftMutationVariables>;

/**
 * __useDeleteDraftMutation__
 *
 * To run a mutation, you first call `useDeleteDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftMutation, { data, loading, error }] = useDeleteDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDraftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftMutation, DeleteDraftMutationVariables>) {
        return Apollo.useMutation<DeleteDraftMutation, DeleteDraftMutationVariables>(DeleteDraftDocument, baseOptions);
      }
export type DeleteDraftMutationHookResult = ReturnType<typeof useDeleteDraftMutation>;
export type DeleteDraftMutationResult = Apollo.MutationResult<DeleteDraftMutation>;
export type DeleteDraftMutationOptions = Apollo.BaseMutationOptions<DeleteDraftMutation, DeleteDraftMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation DeleteNotification($id: String!) {
  deleteNotification(id: $id) {
    _id
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, baseOptions);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const EditAccountDocument = gql`
    mutation EditAccount($id: String!, $firstName: String, $lastName: String, $role: RoleEnum) {
  editAccount(id: $id, role: $role, firstName: $firstName, lastName: $lastName) {
    email
  }
}
    `;
export type EditAccountMutationFn = Apollo.MutationFunction<EditAccountMutation, EditAccountMutationVariables>;

/**
 * __useEditAccountMutation__
 *
 * To run a mutation, you first call `useEditAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountMutation, { data, loading, error }] = useEditAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditAccountMutation(baseOptions?: Apollo.MutationHookOptions<EditAccountMutation, EditAccountMutationVariables>) {
        return Apollo.useMutation<EditAccountMutation, EditAccountMutationVariables>(EditAccountDocument, baseOptions);
      }
export type EditAccountMutationHookResult = ReturnType<typeof useEditAccountMutation>;
export type EditAccountMutationResult = Apollo.MutationResult<EditAccountMutation>;
export type EditAccountMutationOptions = Apollo.BaseMutationOptions<EditAccountMutation, EditAccountMutationVariables>;
export const EditDraftDocument = gql`
    mutation EditDraft($hub: ID, $id: ID!, $title: String, $description: String, $file: Upload, $tags: [ID!], $content: String) {
  editDraft(id: $id, hub: $hub, title: $title, file: $file, tags: $tags, content: $content, description: $description) {
    _id
  }
}
    `;
export type EditDraftMutationFn = Apollo.MutationFunction<EditDraftMutation, EditDraftMutationVariables>;

/**
 * __useEditDraftMutation__
 *
 * To run a mutation, you first call `useEditDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDraftMutation, { data, loading, error }] = useEditDraftMutation({
 *   variables: {
 *      hub: // value for 'hub'
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      file: // value for 'file'
 *      tags: // value for 'tags'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useEditDraftMutation(baseOptions?: Apollo.MutationHookOptions<EditDraftMutation, EditDraftMutationVariables>) {
        return Apollo.useMutation<EditDraftMutation, EditDraftMutationVariables>(EditDraftDocument, baseOptions);
      }
export type EditDraftMutationHookResult = ReturnType<typeof useEditDraftMutation>;
export type EditDraftMutationResult = Apollo.MutationResult<EditDraftMutation>;
export type EditDraftMutationOptions = Apollo.BaseMutationOptions<EditDraftMutation, EditDraftMutationVariables>;
export const FlagDraftDocument = gql`
    mutation FlagDraft($id: String!, $message: String!) {
  flagDraft(id: $id, message: $message)
}
    `;
export type FlagDraftMutationFn = Apollo.MutationFunction<FlagDraftMutation, FlagDraftMutationVariables>;

/**
 * __useFlagDraftMutation__
 *
 * To run a mutation, you first call `useFlagDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlagDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flagDraftMutation, { data, loading, error }] = useFlagDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useFlagDraftMutation(baseOptions?: Apollo.MutationHookOptions<FlagDraftMutation, FlagDraftMutationVariables>) {
        return Apollo.useMutation<FlagDraftMutation, FlagDraftMutationVariables>(FlagDraftDocument, baseOptions);
      }
export type FlagDraftMutationHookResult = ReturnType<typeof useFlagDraftMutation>;
export type FlagDraftMutationResult = Apollo.MutationResult<FlagDraftMutation>;
export type FlagDraftMutationOptions = Apollo.BaseMutationOptions<FlagDraftMutation, FlagDraftMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    role
    _id
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MakeDeleteArticleRequestDocument = gql`
    mutation makeDeleteArticleRequest($id: String!) {
  makeDeleteArticleRequest(id: $id) {
    _id
  }
}
    `;
export type MakeDeleteArticleRequestMutationFn = Apollo.MutationFunction<MakeDeleteArticleRequestMutation, MakeDeleteArticleRequestMutationVariables>;

/**
 * __useMakeDeleteArticleRequestMutation__
 *
 * To run a mutation, you first call `useMakeDeleteArticleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeDeleteArticleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeDeleteArticleRequestMutation, { data, loading, error }] = useMakeDeleteArticleRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMakeDeleteArticleRequestMutation(baseOptions?: Apollo.MutationHookOptions<MakeDeleteArticleRequestMutation, MakeDeleteArticleRequestMutationVariables>) {
        return Apollo.useMutation<MakeDeleteArticleRequestMutation, MakeDeleteArticleRequestMutationVariables>(MakeDeleteArticleRequestDocument, baseOptions);
      }
export type MakeDeleteArticleRequestMutationHookResult = ReturnType<typeof useMakeDeleteArticleRequestMutation>;
export type MakeDeleteArticleRequestMutationResult = Apollo.MutationResult<MakeDeleteArticleRequestMutation>;
export type MakeDeleteArticleRequestMutationOptions = Apollo.BaseMutationOptions<MakeDeleteArticleRequestMutation, MakeDeleteArticleRequestMutationVariables>;
export const PublishDraftDocument = gql`
    mutation PublishDraft($id: String!) {
  publishDraft(id: $id) {
    _id
    title
  }
}
    `;
export type PublishDraftMutationFn = Apollo.MutationFunction<PublishDraftMutation, PublishDraftMutationVariables>;

/**
 * __usePublishDraftMutation__
 *
 * To run a mutation, you first call `usePublishDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDraftMutation, { data, loading, error }] = usePublishDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishDraftMutation(baseOptions?: Apollo.MutationHookOptions<PublishDraftMutation, PublishDraftMutationVariables>) {
        return Apollo.useMutation<PublishDraftMutation, PublishDraftMutationVariables>(PublishDraftDocument, baseOptions);
      }
export type PublishDraftMutationHookResult = ReturnType<typeof usePublishDraftMutation>;
export type PublishDraftMutationResult = Apollo.MutationResult<PublishDraftMutation>;
export type PublishDraftMutationOptions = Apollo.BaseMutationOptions<PublishDraftMutation, PublishDraftMutationVariables>;
export const ReadNotificationDocument = gql`
    mutation ReadNotification($id: String!) {
  readNotification(id: $id) {
    _id
  }
}
    `;
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, baseOptions);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const ResetDocument = gql`
    mutation Reset($id: String!, $password: String!) {
  reset(id: $id, password: $password) {
    _id
    role
  }
}
    `;
export type ResetMutationFn = Apollo.MutationFunction<ResetMutation, ResetMutationVariables>;

/**
 * __useResetMutation__
 *
 * To run a mutation, you first call `useResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetMutation, { data, loading, error }] = useResetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetMutation(baseOptions?: Apollo.MutationHookOptions<ResetMutation, ResetMutationVariables>) {
        return Apollo.useMutation<ResetMutation, ResetMutationVariables>(ResetDocument, baseOptions);
      }
export type ResetMutationHookResult = ReturnType<typeof useResetMutation>;
export type ResetMutationResult = Apollo.MutationResult<ResetMutation>;
export type ResetMutationOptions = Apollo.BaseMutationOptions<ResetMutation, ResetMutationVariables>;
export const ResolveDraftDocument = gql`
    mutation resolveDraft($id: String!) {
  resolveDraft(id: $id)
}
    `;
export type ResolveDraftMutationFn = Apollo.MutationFunction<ResolveDraftMutation, ResolveDraftMutationVariables>;

/**
 * __useResolveDraftMutation__
 *
 * To run a mutation, you first call `useResolveDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveDraftMutation, { data, loading, error }] = useResolveDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResolveDraftMutation(baseOptions?: Apollo.MutationHookOptions<ResolveDraftMutation, ResolveDraftMutationVariables>) {
        return Apollo.useMutation<ResolveDraftMutation, ResolveDraftMutationVariables>(ResolveDraftDocument, baseOptions);
      }
export type ResolveDraftMutationHookResult = ReturnType<typeof useResolveDraftMutation>;
export type ResolveDraftMutationResult = Apollo.MutationResult<ResolveDraftMutation>;
export type ResolveDraftMutationOptions = Apollo.BaseMutationOptions<ResolveDraftMutation, ResolveDraftMutationVariables>;
export const SendResetCodeDocument = gql`
    mutation SendResetCode($email: String!) {
  sendResetCode(email: $email)
}
    `;
export type SendResetCodeMutationFn = Apollo.MutationFunction<SendResetCodeMutation, SendResetCodeMutationVariables>;

/**
 * __useSendResetCodeMutation__
 *
 * To run a mutation, you first call `useSendResetCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetCodeMutation, { data, loading, error }] = useSendResetCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendResetCodeMutation, SendResetCodeMutationVariables>) {
        return Apollo.useMutation<SendResetCodeMutation, SendResetCodeMutationVariables>(SendResetCodeDocument, baseOptions);
      }
export type SendResetCodeMutationHookResult = ReturnType<typeof useSendResetCodeMutation>;
export type SendResetCodeMutationResult = Apollo.MutationResult<SendResetCodeMutation>;
export type SendResetCodeMutationOptions = Apollo.BaseMutationOptions<SendResetCodeMutation, SendResetCodeMutationVariables>;
export const UnflagDraftDocument = gql`
    mutation UnflagDraft($id: String!) {
  unflagDraft(id: $id)
}
    `;
export type UnflagDraftMutationFn = Apollo.MutationFunction<UnflagDraftMutation, UnflagDraftMutationVariables>;

/**
 * __useUnflagDraftMutation__
 *
 * To run a mutation, you first call `useUnflagDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnflagDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unflagDraftMutation, { data, loading, error }] = useUnflagDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnflagDraftMutation(baseOptions?: Apollo.MutationHookOptions<UnflagDraftMutation, UnflagDraftMutationVariables>) {
        return Apollo.useMutation<UnflagDraftMutation, UnflagDraftMutationVariables>(UnflagDraftDocument, baseOptions);
      }
export type UnflagDraftMutationHookResult = ReturnType<typeof useUnflagDraftMutation>;
export type UnflagDraftMutationResult = Apollo.MutationResult<UnflagDraftMutation>;
export type UnflagDraftMutationOptions = Apollo.BaseMutationOptions<UnflagDraftMutation, UnflagDraftMutationVariables>;
export const UpdateNotificationSettingsDocument = gql`
    mutation UpdateNotificationSettings($type: NotificationSettingsType!, $enabled: Boolean!) {
  updateNotificationSettings(type: $type, enabled: $enabled) {
    _id
  }
}
    `;
export type UpdateNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;

/**
 * __useUpdateNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingsMutation, { data, loading, error }] = useUpdateNotificationSettingsMutation({
 *   variables: {
 *      type: // value for 'type'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>) {
        return Apollo.useMutation<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>(UpdateNotificationSettingsDocument, baseOptions);
      }
export type UpdateNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationResult = Apollo.MutationResult<UpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($password: String!, $newPassword: String!) {
  updatePassword(password: $password, newPassword: $newPassword) {
    _id
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, baseOptions);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const AccessTokenDocument = gql`
    query AccessToken {
  accessToken {
    _id
    role
  }
}
    `;

/**
 * __useAccessTokenQuery__
 *
 * To run a query within a React component, call `useAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessTokenQuery(baseOptions?: Apollo.QueryHookOptions<AccessTokenQuery, AccessTokenQueryVariables>) {
        return Apollo.useQuery<AccessTokenQuery, AccessTokenQueryVariables>(AccessTokenDocument, baseOptions);
      }
export function useAccessTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccessTokenQuery, AccessTokenQueryVariables>) {
          return Apollo.useLazyQuery<AccessTokenQuery, AccessTokenQueryVariables>(AccessTokenDocument, baseOptions);
        }
export type AccessTokenQueryHookResult = ReturnType<typeof useAccessTokenQuery>;
export type AccessTokenLazyQueryHookResult = ReturnType<typeof useAccessTokenLazyQuery>;
export type AccessTokenQueryResult = Apollo.QueryResult<AccessTokenQuery, AccessTokenQueryVariables>;
export const AccountDocument = gql`
    query Account($id: String) {
  accountById(id: $id) {
    _id
    email
    role
    firstName
    lastName
    status
  }
}
    `;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountQuery(baseOptions?: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export const AccountsDocument = gql`
    query Accounts {
  accounts {
    _id
    email
    role
    firstName
    lastName
    status
    passwordHistory
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, baseOptions);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, baseOptions);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const ActivationDocument = gql`
    query Activation($id: String!) {
  activationDetails(id: $id) {
    email
  }
}
    `;

/**
 * __useActivationQuery__
 *
 * To run a query within a React component, call `useActivationQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivationQuery(baseOptions?: Apollo.QueryHookOptions<ActivationQuery, ActivationQueryVariables>) {
        return Apollo.useQuery<ActivationQuery, ActivationQueryVariables>(ActivationDocument, baseOptions);
      }
export function useActivationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivationQuery, ActivationQueryVariables>) {
          return Apollo.useLazyQuery<ActivationQuery, ActivationQueryVariables>(ActivationDocument, baseOptions);
        }
export type ActivationQueryHookResult = ReturnType<typeof useActivationQuery>;
export type ActivationLazyQueryHookResult = ReturnType<typeof useActivationLazyQuery>;
export type ActivationQueryResult = Apollo.QueryResult<ActivationQuery, ActivationQueryVariables>;
export const AllRequestsDocument = gql`
    query AllRequests {
  getAllRequests {
    _id
    metaId
    type
    createdAt
    createdByUser {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useAllRequestsQuery__
 *
 * To run a query within a React component, call `useAllRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRequestsQuery(baseOptions?: Apollo.QueryHookOptions<AllRequestsQuery, AllRequestsQueryVariables>) {
        return Apollo.useQuery<AllRequestsQuery, AllRequestsQueryVariables>(AllRequestsDocument, baseOptions);
      }
export function useAllRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllRequestsQuery, AllRequestsQueryVariables>) {
          return Apollo.useLazyQuery<AllRequestsQuery, AllRequestsQueryVariables>(AllRequestsDocument, baseOptions);
        }
export type AllRequestsQueryHookResult = ReturnType<typeof useAllRequestsQuery>;
export type AllRequestsLazyQueryHookResult = ReturnType<typeof useAllRequestsLazyQuery>;
export type AllRequestsQueryResult = Apollo.QueryResult<AllRequestsQuery, AllRequestsQueryVariables>;
export const AllSubmissionsDocument = gql`
    query AllSubmissions {
  hubs {
    _id
    name
    image
  }
  allDrafts {
    ...SubmissionDraft
  }
}
    ${SubmissionDraftFragmentDoc}`;

/**
 * __useAllSubmissionsQuery__
 *
 * To run a query within a React component, call `useAllSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSubmissionsQuery(baseOptions?: Apollo.QueryHookOptions<AllSubmissionsQuery, AllSubmissionsQueryVariables>) {
        return Apollo.useQuery<AllSubmissionsQuery, AllSubmissionsQueryVariables>(AllSubmissionsDocument, baseOptions);
      }
export function useAllSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSubmissionsQuery, AllSubmissionsQueryVariables>) {
          return Apollo.useLazyQuery<AllSubmissionsQuery, AllSubmissionsQueryVariables>(AllSubmissionsDocument, baseOptions);
        }
export type AllSubmissionsQueryHookResult = ReturnType<typeof useAllSubmissionsQuery>;
export type AllSubmissionsLazyQueryHookResult = ReturnType<typeof useAllSubmissionsLazyQuery>;
export type AllSubmissionsQueryResult = Apollo.QueryResult<AllSubmissionsQuery, AllSubmissionsQueryVariables>;
export const ArticleDocument = gql`
    query Article($id: String!) {
  hubs {
    _id
    name
    image
  }
  draft(id: $id) {
    _id
    hub
    title
    description
    image
    content
    status
    tags
    createdByUser {
      _id
      firstName
      lastName
    }
    comments {
      createdByUser {
        _id
        firstName
        lastName
      }
      message
      isImportant
      createdAt
      _id
    }
  }
  tags {
    _id
    name
  }
}
    `;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleQuery(baseOptions?: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
      }
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const DashboardDocument = gql`
    query Dashboard {
  recentActivity {
    type
    subDescription
    description
    createdAt
    _id
  }
  articleCount
  contributionsCount
  publishedCount
  flaggedDraftsCount
}
    `;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const HubsTagsDocument = gql`
    query HubsTags {
  hubs {
    _id
    name
    ordinal
    image
  }
  tags {
    _id
    name
  }
}
    `;

/**
 * __useHubsTagsQuery__
 *
 * To run a query within a React component, call `useHubsTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHubsTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHubsTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHubsTagsQuery(baseOptions?: Apollo.QueryHookOptions<HubsTagsQuery, HubsTagsQueryVariables>) {
        return Apollo.useQuery<HubsTagsQuery, HubsTagsQueryVariables>(HubsTagsDocument, baseOptions);
      }
export function useHubsTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HubsTagsQuery, HubsTagsQueryVariables>) {
          return Apollo.useLazyQuery<HubsTagsQuery, HubsTagsQueryVariables>(HubsTagsDocument, baseOptions);
        }
export type HubsTagsQueryHookResult = ReturnType<typeof useHubsTagsQuery>;
export type HubsTagsLazyQueryHookResult = ReturnType<typeof useHubsTagsLazyQuery>;
export type HubsTagsQueryResult = Apollo.QueryResult<HubsTagsQuery, HubsTagsQueryVariables>;
export const MyRequestsDocument = gql`
    query MyRequests {
  getMyPendingRequests {
    _id
    metaId
    type
    createdAt
  }
  getMyPastRequests {
    _id
    metaId
    type
    createdAt
  }
}
    `;

/**
 * __useMyRequestsQuery__
 *
 * To run a query within a React component, call `useMyRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRequestsQuery(baseOptions?: Apollo.QueryHookOptions<MyRequestsQuery, MyRequestsQueryVariables>) {
        return Apollo.useQuery<MyRequestsQuery, MyRequestsQueryVariables>(MyRequestsDocument, baseOptions);
      }
export function useMyRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyRequestsQuery, MyRequestsQueryVariables>) {
          return Apollo.useLazyQuery<MyRequestsQuery, MyRequestsQueryVariables>(MyRequestsDocument, baseOptions);
        }
export type MyRequestsQueryHookResult = ReturnType<typeof useMyRequestsQuery>;
export type MyRequestsLazyQueryHookResult = ReturnType<typeof useMyRequestsLazyQuery>;
export type MyRequestsQueryResult = Apollo.QueryResult<MyRequestsQuery, MyRequestsQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications {
  notifications {
    _id
    type
    description
    meta
    createdAt
    read
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const ParenthubArticleDocument = gql`
    query ParenthubArticle($id: String!) {
  article(articleId: $id) {
    _id
    title
    image
    description
    content
    canMakeDeleteArticleRequest
  }
}
    `;

/**
 * __useParenthubArticleQuery__
 *
 * To run a query within a React component, call `useParenthubArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useParenthubArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParenthubArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useParenthubArticleQuery(baseOptions?: Apollo.QueryHookOptions<ParenthubArticleQuery, ParenthubArticleQueryVariables>) {
        return Apollo.useQuery<ParenthubArticleQuery, ParenthubArticleQueryVariables>(ParenthubArticleDocument, baseOptions);
      }
export function useParenthubArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParenthubArticleQuery, ParenthubArticleQueryVariables>) {
          return Apollo.useLazyQuery<ParenthubArticleQuery, ParenthubArticleQueryVariables>(ParenthubArticleDocument, baseOptions);
        }
export type ParenthubArticleQueryHookResult = ReturnType<typeof useParenthubArticleQuery>;
export type ParenthubArticleLazyQueryHookResult = ReturnType<typeof useParenthubArticleLazyQuery>;
export type ParenthubArticleQueryResult = Apollo.QueryResult<ParenthubArticleQuery, ParenthubArticleQueryVariables>;
export const ParenthubArticlesDocument = gql`
    query ParenthubArticles($id: String!) {
  articles(hubId: $id) {
    _id
    title
    image
    description
  }
}
    `;

/**
 * __useParenthubArticlesQuery__
 *
 * To run a query within a React component, call `useParenthubArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useParenthubArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParenthubArticlesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useParenthubArticlesQuery(baseOptions?: Apollo.QueryHookOptions<ParenthubArticlesQuery, ParenthubArticlesQueryVariables>) {
        return Apollo.useQuery<ParenthubArticlesQuery, ParenthubArticlesQueryVariables>(ParenthubArticlesDocument, baseOptions);
      }
export function useParenthubArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParenthubArticlesQuery, ParenthubArticlesQueryVariables>) {
          return Apollo.useLazyQuery<ParenthubArticlesQuery, ParenthubArticlesQueryVariables>(ParenthubArticlesDocument, baseOptions);
        }
export type ParenthubArticlesQueryHookResult = ReturnType<typeof useParenthubArticlesQuery>;
export type ParenthubArticlesLazyQueryHookResult = ReturnType<typeof useParenthubArticlesLazyQuery>;
export type ParenthubArticlesQueryResult = Apollo.QueryResult<ParenthubArticlesQuery, ParenthubArticlesQueryVariables>;
export const ProfileDocument = gql`
    query Profile($id: String, $lastMonth: DateTime) {
  accountById(id: $id) {
    _id
    email
    role
    firstName
    lastName
    status
    notificationSettings {
      email
      inApp
      realTime
    }
  }
  contributionsCount
  publishedCount
  monthContributionsCount: contributionsCount(dateFrom: $lastMonth)
  monthPublishedCount: publishedCount(dateFrom: $lastMonth)
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lastMonth: // value for 'lastMonth'
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, baseOptions);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, baseOptions);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const ResetDetailsDocument = gql`
    query ResetDetails($id: String!) {
  resetDetails(id: $id) {
    email
  }
}
    `;

/**
 * __useResetDetailsQuery__
 *
 * To run a query within a React component, call `useResetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResetDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ResetDetailsQuery, ResetDetailsQueryVariables>) {
        return Apollo.useQuery<ResetDetailsQuery, ResetDetailsQueryVariables>(ResetDetailsDocument, baseOptions);
      }
export function useResetDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResetDetailsQuery, ResetDetailsQueryVariables>) {
          return Apollo.useLazyQuery<ResetDetailsQuery, ResetDetailsQueryVariables>(ResetDetailsDocument, baseOptions);
        }
export type ResetDetailsQueryHookResult = ReturnType<typeof useResetDetailsQuery>;
export type ResetDetailsLazyQueryHookResult = ReturnType<typeof useResetDetailsLazyQuery>;
export type ResetDetailsQueryResult = Apollo.QueryResult<ResetDetailsQuery, ResetDetailsQueryVariables>;
export const RolesDocument = gql`
    query Roles {
  roles {
    role
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const SubmissionsDocument = gql`
    query Submissions {
  hubs {
    _id
    name
    image
  }
  myDrafts {
    ...SubmissionDraft
  }
}
    ${SubmissionDraftFragmentDoc}`;

/**
 * __useSubmissionsQuery__
 *
 * To run a query within a React component, call `useSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubmissionsQuery(baseOptions?: Apollo.QueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>) {
        return Apollo.useQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, baseOptions);
      }
export function useSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>) {
          return Apollo.useLazyQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, baseOptions);
        }
export type SubmissionsQueryHookResult = ReturnType<typeof useSubmissionsQuery>;
export type SubmissionsLazyQueryHookResult = ReturnType<typeof useSubmissionsLazyQuery>;
export type SubmissionsQueryResult = Apollo.QueryResult<SubmissionsQuery, SubmissionsQueryVariables>;
export const FlaggedDraftDocument = gql`
    subscription FlaggedDraft {
  flaggedDraft {
    draft {
      _id
    }
    date
  }
}
    `;

/**
 * __useFlaggedDraftSubscription__
 *
 * To run a query within a React component, call `useFlaggedDraftSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFlaggedDraftSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlaggedDraftSubscription({
 *   variables: {
 *   },
 * });
 */
export function useFlaggedDraftSubscription(baseOptions?: Apollo.SubscriptionHookOptions<FlaggedDraftSubscription, FlaggedDraftSubscriptionVariables>) {
        return Apollo.useSubscription<FlaggedDraftSubscription, FlaggedDraftSubscriptionVariables>(FlaggedDraftDocument, baseOptions);
      }
export type FlaggedDraftSubscriptionHookResult = ReturnType<typeof useFlaggedDraftSubscription>;
export type FlaggedDraftSubscriptionResult = Apollo.SubscriptionResult<FlaggedDraftSubscription>;
export const NewDraftDocument = gql`
    subscription NewDraft {
  newDraft {
    draft {
      _id
    }
    date
  }
}
    `;

/**
 * __useNewDraftSubscription__
 *
 * To run a query within a React component, call `useNewDraftSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewDraftSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewDraftSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewDraftSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NewDraftSubscription, NewDraftSubscriptionVariables>) {
        return Apollo.useSubscription<NewDraftSubscription, NewDraftSubscriptionVariables>(NewDraftDocument, baseOptions);
      }
export type NewDraftSubscriptionHookResult = ReturnType<typeof useNewDraftSubscription>;
export type NewDraftSubscriptionResult = Apollo.SubscriptionResult<NewDraftSubscription>;
export const PublishedArticleDocument = gql`
    subscription PublishedArticle {
  publishedArticle {
    article {
      _id
    }
    date
  }
}
    `;

/**
 * __usePublishedArticleSubscription__
 *
 * To run a query within a React component, call `usePublishedArticleSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishedArticleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedArticleSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePublishedArticleSubscription(baseOptions?: Apollo.SubscriptionHookOptions<PublishedArticleSubscription, PublishedArticleSubscriptionVariables>) {
        return Apollo.useSubscription<PublishedArticleSubscription, PublishedArticleSubscriptionVariables>(PublishedArticleDocument, baseOptions);
      }
export type PublishedArticleSubscriptionHookResult = ReturnType<typeof usePublishedArticleSubscription>;
export type PublishedArticleSubscriptionResult = Apollo.SubscriptionResult<PublishedArticleSubscription>;