import React, {  } from "react";
import { Route } from "react-router";

import {
    makeStyles} from "@material-ui/core";

import { CCDrawer } from "../components/Drawer";
import { OverlayNotifications } from "../components";

interface Props {
    component: React.ElementType;
    path: string;
    isModal?: boolean;
}

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
    },
}));

export const LoggedInRoute: React.FC<Props> = ({ isModal, component: Component, ...rest }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {!isModal && <CCDrawer />}
            <Route
                {...rest}
                render={props =>
                    <Component {...props} />
                }
            />
            <OverlayNotifications />
        </div>
    );
};
