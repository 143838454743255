import React, { FunctionComponent } from "react";
import { Route, Switch, useLocation } from "react-router";

import {
    ActivationScreen, AddArticleScreen, AddUserScreen, DashboardScreen, EditUserScreen,
    ForgotPasswordScreen, LoginScreen, ManageArticles, ManageParentHubScreen, ResetPasswordScreen,
    ReviewArticles, ReviewRequestsScreen, UsersScreen
} from "../screens";
import { EditDraftScreen } from "../screens/EditDraft";
import { ProfileScreen } from "../screens/Profile";
import { UpdatePasswordScreen } from "../screens/UpdatePassword";
import { HomeRoute } from "./HomeRoute";
import { PrivateRoute } from "./PrivateRoute";

const Routes: FunctionComponent = () => {
    const location = useLocation<{background?: string}>();
    const isModal = location.state?.background;

    return (
        <>
            <Switch>
                <Route exact path={"/"} component={HomeRoute} />
                <Route path={"/login"} component={LoginScreen} />
                <Route path={"/activate/:id"} component={ActivationScreen} />
                <Route path={"/reset-password/:id"} component={ResetPasswordScreen} />
                <Route path={"/forgot-password"} component={ForgotPasswordScreen} />
                <PrivateRoute path={"/dashboard"} component={DashboardScreen} />
                <PrivateRoute path={"/manage/users"} component={UsersScreen} />
                <PrivateRoute path={"/parenthub/add-article"} component={AddArticleScreen} />
                <PrivateRoute exact path={"/parenthub/manage-submissions"} component={ManageArticles} />
                <PrivateRoute path={"/parenthub/manage-submissions/:id"} component={ManageArticles} />
                <PrivateRoute exact path={"/parenthub/manage-submissions/:id/edit"} component={EditDraftScreen} />
                <PrivateRoute exact path={"/parenthub/review-submissions"} component={ReviewArticles} />
                <PrivateRoute exact path={"/parenthub/review-submissions/:id"} component={ReviewArticles} />
                <PrivateRoute exact path={"/parenthub/manage-all"} component={ManageParentHubScreen} />
                <PrivateRoute path={"/parenthub/manage-all/:id"} component={ManageParentHubScreen} />
                <PrivateRoute path={"/parenthub/review-requests"} component={ReviewRequestsScreen} />
                <PrivateRoute path={"/profile"} component={ProfileScreen} />
                <PrivateRoute exact path={"/profile/update-password"} component={UpdatePasswordScreen} />
            </Switch>
            {isModal && <PrivateRoute isModal path={"/manage/users/add"} component={AddUserScreen} />}
            {isModal && <PrivateRoute isModal path={"/manage/users/:id/edit"} component={EditUserScreen} />}
            {isModal && <PrivateRoute isModal path={"/parenthub/manage-submissions/:id/edit"} component={EditDraftScreen} />}
            {isModal && <PrivateRoute isModal path={"/profile/update-password"} component={UpdatePasswordScreen} />}
        </>
    );
};

export default Routes;
