import React, { FunctionComponent, useEffect } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

import {
    Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import WarningIcon from "@material-ui/icons/Warning";

import { useLoadingBarStore } from "../";
import {
    ArticleStatus, useAllSubmissionsQuery, useSubmissionsQuery
} from "../../generated/generated-types";

export const SubmissionList: FunctionComponent<{
    expanded: boolean,
    reviewMode?: boolean
}> = (props) => {
    const { expanded, reviewMode } = props;

    return (
        <div style={{
            width: expanded
                ? 300
                : undefined,
            flex: expanded ? undefined : 1,
        }}>
            <Typography gutterBottom style={{
                paddingLeft: expanded ? 16 : 0,
                paddingTop: expanded ? 16 : 0,
            }}>
                {reviewMode ? "Showing all submissions" : "Showing my submissions"}
            </Typography>
            {reviewMode
                ? <AllSubmissions expanded={expanded} />
                : <MySubmissions expanded={expanded} />
            }
        </div>
    );
};

const MySubmissions: React.FC<{ expanded: boolean }> = (props) => {
    const match = useRouteMatch<{ id: string }>();
    const { data, loading: submissionLoading } = useSubmissionsQuery();

    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(submissionLoading);
    }, [submissionLoading, setLoadingStore]);

    if (!data?.myDrafts) {
        return <></>;
    }

    return (
        <List>
            {data.myDrafts.map((draft) => {
                const hubName = data.hubs.find((hub) => hub._id === draft.hub);

                return (
                    <SubmissionItem
                        key={draft._id}
                        title={draft.title}
                        description={draft.description}
                        avatarSrc={hubName?.image}
                        expanded={props.expanded}
                        selected={draft._id === match.params.id}
                        toLink={`/parenthub/manage-submissions/${draft._id}`}
                        needsAttention={draft.status === ArticleStatus.Needschanges}
                    />
                );
            })}
        </List>
    );
};

const AllSubmissions: React.FC<{ expanded: boolean }> = (props) => {
    const match = useRouteMatch<{ id: string }>();
    const { data, loading: submissionLoading } = useAllSubmissionsQuery();

    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(submissionLoading);
    }, [submissionLoading, setLoadingStore]);

    if (!data?.allDrafts) {
        return <></>;
    }

    return (
        <List>
            {data.allDrafts.map((draft) => {
                const hubName = data.hubs.find((hub) => hub._id === draft.hub);

                return (
                    <SubmissionItem
                        key={draft._id}
                        title={draft.title}
                        description={draft.description}
                        avatarSrc={hubName?.image}
                        expanded={props.expanded}
                        selected={draft._id === match.params.id}
                        toLink={`/parenthub/review-submissions/${draft._id}`}
                        needsAttention={draft.status === ArticleStatus.Needschanges}
                    />
                );
            })}
        </List>
    );
};

interface SubmissionItem {
    title: string;
    description: string;
    selected: boolean;
    toLink: string;
    avatarSrc?: string;
    expanded: boolean;
    needsAttention: boolean;
}

const SubmissionItem: React.FC<SubmissionItem> = (props) => {
    const { title, needsAttention, description,
        selected, toLink, avatarSrc, expanded
    } = props;

    return (
        <ListItem
            button
            component={NavLink}
            selected={selected}
            to={toLink}
        >
            <ListItemAvatar>
                <Avatar src={avatarSrc} />
            </ListItemAvatar>
            <ListItemText
                primary={title}
                secondary={description}
            />
            {(needsAttention || !expanded) && <ListItemSecondaryAction>
                <IconButton edge="end">
                    {needsAttention
                        ? <WarningIcon color={"error"} />
                        : !expanded
                            ? <ArrowForwardIcon />
                            : undefined
                    }
                </IconButton>
            </ListItemSecondaryAction>}
        </ListItem>
    );
};
