import React, { useCallback, useEffect, useMemo } from "react";

import { Button } from "@material-ui/core";

import { useLoadingBarStore, useSnackbarStore } from "../../..";
import { useResolveDraftMutation, useSubmissionsQuery } from "../../../../generated/generated-types";

export const MarkedAsResolvedButton: React.FC<{ draftId?: string }> = (props) => {
    const { draftId } = props;

    const { refetch } = useSubmissionsQuery();

    const [resolveDraft, { loading: resolveDraftLoading }] = useResolveDraftMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: async () => {
            await refetch();
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(resolveDraftLoading);
    }, [resolveDraftLoading, setLoadingStore]);

    const resolve = useCallback(
        () => {
            if (draftId) {
                resolveDraft({variables: {id: draftId}});
            }
        },
        [draftId, resolveDraft],
    );

    return (
        <Button
            color={"primary"}
            variant={"contained"}
            onClick={resolve}
            disabled={typeof draftId === "undefined"}
        >
            Mark as resolved
        </Button>
    );
};