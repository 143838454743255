import { Card, CardActions, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";

interface DashboardCardProps {
    statistic: number;
    description: string;
    button?: JSX.Element;
}

export const DashboardCard: React.FC<DashboardCardProps> = (props) => {
    const classes = useStyles();

    return (
        <Card className={classes.topCard}>
            <CardContent>
                <Typography style={{ fontSize: 36 }}>
                    {props.statistic}
                </Typography>
                <Typography style={{ fontSize: 18 }}>
                    {props.description}
                </Typography>
            </CardContent>
            {props.button &&
                <CardActions>
                    {props.button}
                </CardActions>
            }
        </Card>
    );
};