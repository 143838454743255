import React, { FunctionComponent, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";

import {
    Avatar, Card, List, ListItem, ListItemAvatar, ListItemText, ListSubheader,
    Typography} from "@material-ui/core";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

import {
    Header, Page, ParentHubBubble, PreviewArticle, useLoadingBarStore
} from "../../components";
import {
    RequestType, useHubsTagsQuery, useMyRequestsQuery, useParenthubArticleQuery, useParenthubArticlesLazyQuery
} from "../../generated/generated-types";
import { useStyles } from "./styles";
import moment from "moment";

export const ManageParentHubScreen: FunctionComponent = () => {
    const classes = useStyles();
    const match = useRouteMatch<{ id: string }>();
    const hasArticleSelected = !!match.params.id;
    
    const { data, loading: loadingHubTags } = useHubsTagsQuery();
    const [getArticles, { data: parenthubArticles, loading: parenthubArticlesLoading }] = useParenthubArticlesLazyQuery();

    const [selectedHub, setSelectedHubId] = useState<{ id: string, title: string } | undefined>(undefined);
    useEffect(() => {
        if (data?.hubs) {
            const hub = data.hubs[0];

            setSelectedHubId({ id: hub._id, title: hub.name });
        }
    }, [loadingHubTags, setSelectedHubId, data?.hubs]);

    useEffect(() => {
        if (selectedHub) {
            getArticles({ variables: { id: selectedHub.id } });
        }
    }, [selectedHub, getArticles]);

    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(parenthubArticlesLoading);
    }, [parenthubArticlesLoading, setLoadingStore]);

    return (
        <>
            <Page
                header={
                    <Header title={"Manage ParentHub"} />
                }
            >
                <MyRequestsOverview />
                <div className={classes.root}>
                    {data?.hubs.map((hub, index) =>
                        <ParentHubBubble
                            key={`select_hub-${index}`}
                            name={hub.name}
                            selected={selectedHub?.id === hub._id}
                            image={hub.image}
                            id={hub._id}
                            onClick={() => setSelectedHubId({ id: hub._id, title: hub.name })}
                        />
                    )}
                </div>
                <Typography style={{ fontWeight: "bold", marginTop: 32, marginLeft: 16 }}>
                    {selectedHub?.title}
                </Typography>
                <List>
                    {parenthubArticles?.articles.map((article) => (
                        <ListItem
                            key={article._id}
                            button
                            component={NavLink}
                            to={`/parenthub/manage-all/${article._id}`}
                        >
                            <ListItemText
                                primary={article.title}
                                secondary={article.description}
                            />
                            <ListItemAvatar>
                                <Avatar alt={article.title} src={article.image} />
                            </ListItemAvatar>
                        </ListItem>
                    )
                    )}
                </List>
                {hasArticleSelected &&
                    <PreviewArticle
                        articleId={match.params.id}
                    />
                }
            </Page>
        </>
    );
};

const MyRequestsOverview = () => {
    const { data } = useMyRequestsQuery();

    if (!data?.getMyPendingRequests || data?.getMyPendingRequests?.length === 0) {
        return <></>;
    }

    return (
        <Card style={{ marginBottom: 32 }}>
            <List
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Pending Requests
                    </ListSubheader>
                }
            >
                {data?.getMyPendingRequests.map((d) => {
                    const type = d.type === RequestType.Deletearticle
                        ? "Delete Article"
                        : d.type;
                    
                    return (
                        <MyRequestItem
                            key={d._id}
                            id={d.metaId}
                            type={type}
                            createdAt={d.createdAt}
                        />
                    );
                })
                }
            </List>
        </Card>
    );
};

const MyRequestItem: React.FC<{id: string, type: string, createdAt: string}> = (props) => {
    const { id, type, createdAt } = props;

    const { data: articleData } = useParenthubArticleQuery({
        variables: {
            id
        }
    });

    return (
        <ListItem
            dense
            key={id}
        >
            <DeleteSweepIcon style={{marginRight: 16, color: "rgba(0,0,0,0.54)"}} />
            <ListItemText
                primary={type}
                secondary={`${articleData?.article.title} - submitted ${moment(createdAt).fromNow()}`}
            />
        </ListItem>
    );
};
