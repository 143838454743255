import clsx from "clsx";
import React, { FunctionComponent, useCallback } from "react";
import { useHistory } from "react-router";

import {
    AppBar, IconButton, makeStyles, Menu, MenuItem, Toolbar, Typography, useMediaQuery, useTheme
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

import { useDrawerStore } from "../Drawer";
import { PopupNotificationMenu } from "../PopupNotificationMenu";

interface HeaderProps {
    title?: string;
    secondary?: boolean;
    backUrlPath?: string;
    hideElevation?: boolean;
    hideSecondaryButtons?: boolean;
    hideMenuButton?: boolean;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    menuWrapper: {
        position: "absolute",
        marginTop: 32,
    },
}));

export const Header: FunctionComponent<HeaderProps> = (props) => {
    const { openDrawer, open, closeDrawer } = useDrawerStore(state => ({
        open: state.open,
        openDrawer: state.openDrawer,
        closeDrawer: state.closeDrawer
    }));

    const classes = useStyles();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("lg"));
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const [anchorElNotifications, setAnchorElNNotifications] = React.useState<null | HTMLElement>(null);
    const isNotificationsOpen = Boolean(anchorElNotifications);

    const handleNotificationsOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNNotifications(event.currentTarget);
    }, []);

    const handleNotificationsClose = useCallback(() => {
        setAnchorElNNotifications(null);
    }, []);

    const logout = useCallback(() => {
        handleMenuClose();
        closeDrawer();
        localStorage.clear();

        history.replace("/login");
    }, [history, handleMenuClose]);

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            classes={{
                paper: classes.menuWrapper
            }}
            onClose={handleMenuClose}
            disableScrollLock
        >
            <MenuItem onClick={() => {
                history.push("/profile");
                handleMenuClose();
            }}>
                <AccountCircle className={classes.menuButton} />
                <Typography>
                    Profile
                </Typography>
            </MenuItem>
            <MenuItem onClick={logout}>
                <ExitToAppIcon className={classes.menuButton} />
                <Typography>
                    Logout
                </Typography>
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <AppBar
                position={"fixed"}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: desktop ? open : undefined,
                })}
                elevation={props.hideElevation ? 0 : undefined}
            >
                <Toolbar style={{ minHeight: 48 }}>
                    {!props.hideMenuButton && <IconButton
                        color={"inherit"}
                        aria-label={"open drawer"}
                        onClick={props.backUrlPath
                            ? () => history.push(props.backUrlPath as string)
                            : props.secondary
                                ? history.goBack
                                : openDrawer
                        }
                        edge={"start"}
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        {props.secondary ? <ArrowBackIcon /> : <MenuIcon />}
                    </IconButton>
                    }
                    <Typography variant={"h6"} style={{ flex: 1 }}>
                        {props.title || "Content Creator"}
                    </Typography>
                    {!props.secondary && !props.hideSecondaryButtons &&
                        <><IconButton
                            edge="end"
                            size={"medium"}
                            aria-label={"notifications of current user"}
                            aria-haspopup={"true"}
                            onClick={handleNotificationsOpen}
                            color={"inherit"}
                            style={{marginRight: 4}}
                        >
                            <NotificationsNoneIcon />
                        </IconButton>
                        <IconButton
                            edge="end"
                            size={"medium"}
                            aria-label={"account of current user"}
                            aria-haspopup={"true"}
                            onClick={handleProfileMenuOpen}
                            color={"inherit"}
                        >
                            <AccountCircle />
                        </IconButton>
                        </>
                    }
                </Toolbar>
            </AppBar>
            {!props.secondary && !props.hideSecondaryButtons &&
                <>
                    {renderMenu}
                    <PopupNotificationMenu
                        anchorEl={anchorElNotifications}
                        open={isNotificationsOpen}
                        handleMenuClose={handleNotificationsClose}
                    />
                </>
            }
            <div className={classes.drawerHeader} />
        </>
    );
};

