import { Formik } from "formik";
import startcase from "lodash.startcase";
import React, { FunctionComponent } from "react";
import { useHistory, useRouteMatch } from "react-router";

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
    InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

import { Header, useSnackbarStore } from "../../components";
import {
    RoleEnum, useAccountQuery, useAccountsQuery, useEditAccountMutation, useRolesQuery
} from "../../generated/generated-types";
import { useStyles } from "./styles";

export const EditUserScreen: FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const match = useRouteMatch<{id: string}>();

    const { data: rolesData } = useRolesQuery();
    const { data, loading } = useAccountQuery({variables: {id: match.params.id}});
    const { refetch } = useAccountsQuery();

    const [editAccount] = useEditAccountMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: async () => {
            await refetch();
            history.goBack();
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);

    if (!data && loading) {
        return <></>;
    }

    return (
        <Dialog
            fullScreen={mobile}
            maxWidth={"lg"}
            onClose={history.goBack}
            open={true}
            classes={{paper: classes.dialog}}
        >
            <Formik
                initialValues={{
                    email: data?.accountById?.email,
                    role: data?.accountById?.role,
                    firstName: data?.accountById?.firstName,
                    lastName: data?.accountById?.lastName,
                }}
                validate={values => {
                    const errors: {
                        firstName?: string;
                        lastName?: string;
                    } = {};

                    if (!values.lastName) {
                        errors.lastName = "Required";
                    }

                    if (!values.firstName) {
                        errors.firstName = "Required";
                    }

                    return errors;
                }}
                onSubmit={async (values) => {
                    const role = values.role === "admin"
                        ? RoleEnum.Admin
                        : values.role === "creator"
                            ? RoleEnum.Creator
                            : RoleEnum.Qa;

                    await editAccount({
                        variables: {
                            id: match.params.id,
                            role,
                            firstName: values.firstName,
                            lastName: values.lastName,
                        },
                    });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    submitForm,
                    isSubmitting,
                }) => {
                    return (
                        <>
                            {mobile
                                ? <Header title={"Edit User"} secondary />
                                : <DialogTitle>Edit User</DialogTitle>
                            }
                            <DialogContent>
                                <DialogContentText>
                                    This user is currently {data?.accountById?.status}.
                                </DialogContentText>

                                <TextField
                                    margin={"dense"}
                                    id={"email"}
                                    label={"Email Address"}
                                    disabled
                                    type={"email"}
                                    name={"email"}
                                    error={!!errors.email && touched.email}
                                    helperText={errors.email}
                                    fullWidth
                                    value={values.email}
                                    style={{ marginBottom: 16 }}
                                />
                                <div style={{display: "flex"}}>                                       
                                    <TextField
                                        margin={"dense"}
                                        id={"firstName"}
                                        label={"First Name"}
                                        type={"firstName"}
                                        name={"firstName"}
                                        error={!!errors.email && touched.email}
                                        helperText={errors.email}
                                        fullWidth
                                        value={values.firstName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{ marginBottom: 16, marginRight: 16 }}
                                    />
                                    <TextField
                                        margin={"dense"}
                                        id={"lastName"}
                                        label={"Last Name"}
                                        type={"lastName"}
                                        name={"lastName"}
                                        error={!!errors.email && touched.email}
                                        helperText={errors.email}
                                        fullWidth
                                        value={values.lastName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{ marginBottom: 16 }}
                                    />
                                </div>
                                <FormControl
                                    required
                                    className={classes.formControl}
                                    error={!!errors.role && touched.role}
                                    style={{ marginBottom: 32 }}
                                >
                                    <InputLabel id={"select-role"}>Role</InputLabel>
                                    <Select
                                        labelId={"select-role-label"}
                                        id={"select-role"}
                                        value={values.role}
                                        name={"role"}
                                        onChange={handleChange}
                                    >
                                        {rolesData?.roles && rolesData?.roles.map((role) =>
                                            <MenuItem key={role.role} value={role.role}>{startcase(role.role)}</MenuItem>)
                                        }
                                    </Select>
                                    {!!errors.role && touched.role && <FormHelperText error>{errors.role}</FormHelperText>}
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button color={"primary"} onClick={history.goBack}>
                                    Cancel
                                </Button>
                                <Button color={"primary"}
                                    onClick={submitForm}
                                    disabled={isSubmitting}
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </>
                    );
                }
                }
            </Formik>
        </Dialog>
    );
};
