import moment from "moment";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";

import { Button, Chip, Container, Divider, Typography } from "@material-ui/core";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";

import { ParentHubBubble, useLoadingBarStore, useSnackbarStore } from "..";
import {
    ArticleStatus, RoleEnum, useAllSubmissionsQuery, useArticleQuery, useDashboardQuery,
    usePublishDraftMutation, useSubmissionsQuery
} from "../../generated/generated-types";
import {
    AddCommentField, DeleteButton, FlagChangesButton, MarkedAsResolvedButton, UnflagChangesButton
} from "./components";
import { useStyles } from "./styles";
import { markedDownContent } from "../../utils/markedDownContent";
import { Link, NavLink } from "react-router-dom";
import getRole from "../../utils/getRole";

const dompurify = require("dompurify");
const { Checkmark } = require("react-checkmark");

interface ViewArticleProps {
    id: string;
    reviewMode?: boolean;
}

export const PreviewDraft: FunctionComponent<ViewArticleProps> = (props) => {
    const classes = useStyles();
    const { data: articleData, loading: articleLoading, refetch: refetchArticle } = useArticleQuery({
        variables: {
            id: props.id,
        }
    });

    const [ publishedDraft, setPublishedDraft ] = useState(false);
    const { refetch } = useSubmissionsQuery();
    const { refetch: refetchAllSubmissions } = useAllSubmissionsQuery();
    const { refetch: refetchRecentActivity } = useDashboardQuery();
    
    // Clicked on another ID, get rid of the tick
    useEffect(() => {
        setPublishedDraft(false);
        refetchArticle({ id: props.id });
    }, [props.id, setPublishedDraft, refetchArticle]);

    const [publishDraft, { loading: publishDraftLoading, data: publishedArticleUrl }] = usePublishDraftMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: async () => {
            setPublishedDraft(true);
            await refreshAll();
        }
    });

    const role = useMemo(() => getRole(), []);
    const refreshAll = useCallback(async () => {
        await refetch();
        await refetchRecentActivity();

        if (role === RoleEnum.Admin || role === RoleEnum.Qa) {
            await refetchAllSubmissions();
        }
    }, [refetch, refetchAllSubmissions, refetchRecentActivity]);

    const publish = useCallback(
        () => {
            if (articleData?.draft?._id) {
                publishDraft({ variables: { id: articleData.draft._id } });
            }
        },
        [articleData?.draft?._id],
    );

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(articleLoading || publishDraftLoading);
    }, [articleLoading, publishDraftLoading]);

    if (publishedDraft) {
        return (
            <div className={classes.previewModeContainer}>
                <Checkmark size={180} />
                <Container style={{ alignItems: "center", flexDirection: "column", display: "flex", marginTop: 32 }}>
                    <Typography>
                        Article Published
                    </Typography>
                    {publishedArticleUrl?.publishDraft && <Typography>
                        View <Link
                            to={`/parenthub/manage-all/${publishedArticleUrl?.publishDraft._id}`}>
                                {publishedArticleUrl?.publishDraft.title}
                            </Link>
                    </Typography>}
                </Container>
            </div>
        );
    }

    const authorName = `${articleData?.draft?.createdByUser.firstName} ${articleData?.draft?.createdByUser.lastName}`;
    const needsChanges = articleData?.draft?.status === ArticleStatus.Needschanges;

    return (
        <div className={classes.previewModeContainer}>
            <div className={classes.previewModeTopBar}>
                <div style={{ marginBottom: 8 }}>
                    <Typography variant={"h6"}>
                        {props.reviewMode
                            ? `Submission Request By ${authorName}`
                            : "Your Submission"
                        }
                    </Typography>
                    {needsChanges &&
                        <Typography>
                            This article has been flagged for changes
                        </Typography>
                    }
                </div>
                <div style={{ justifyContent: "flex-end" }}>
                    {props.reviewMode ?
                        <>
                            {needsChanges
                                ? <UnflagChangesButton
                                    draftId={articleData?.draft?._id}
                                />
                                : <FlagChangesButton
                                    refetchArticle={refetchArticle}
                                    draftId={articleData?.draft?._id}
                                />
                            }
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                onClick={publish}
                            >
                                Publish
                            </Button>
                        </>
                        :
                        <>
                            <Button
                                color={"primary"}
                                component={NavLink}
                                to={{
                                    pathname: `/parenthub/manage-submissions/${props.id}/edit`,
                                    state: {
                                        background: `/parenthub/manage-submissions/${props.id}`
                                    }
                                }}
                            >
                                Edit Draft
                            </Button>
                            <DeleteButton
                                draftId={articleData?.draft?._id}
                                refetch={refreshAll}
                            />
                            {needsChanges &&
                                <MarkedAsResolvedButton
                                    draftId={articleData?.draft?._id}
                                />
                            }
                        </>
                    }
                </div>
            </div>
            <div className={classes.root}>
                {articleData?.hubs.map((hub, index) =>
                    <ParentHubBubble
                        key={`select_hub-${index}`}
                        name={hub.name}
                        selected={articleData.draft?.hub === hub._id}
                        image={hub.image}
                        id={hub._id}
                    />
                )}
            </div>
            <img src={articleData?.draft?.image} style={{width: "100%"}} />
            <Typography className={classes.header}>
                Title
            </Typography>
            <Typography>
                {articleData?.draft?.title}
            </Typography>
            <Typography className={classes.header}>
                Description
            </Typography>
            <Typography>
                {articleData?.draft?.description}
            </Typography>
            <Typography className={classes.header}>
                Tags
            </Typography>
            {articleData?.draft?.tags?.map((tag) => {
                const tagName = articleData?.tags?.find((t) => t._id === tag);

                return (
                    <Chip
                        key={tagName?._id}
                        label={tagName?.name}
                        clickable
                        variant={"outlined"}
                        className={classes.chip}
                    />
                );
            })}
            <Typography className={classes.header}>
                Content
            </Typography>
            <Typography>
                {articleData?.draft?.content &&
                    <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(markedDownContent(articleData?.draft?.content)) }} />
                }
            </Typography>
            <Divider />
            <div style={{ marginTop: 16, marginBottom: 16 }}>
                <Typography style={{ marginBottom: 16, fontWeight: "bold" }}>
                    Comments
                </Typography>
                <AddCommentField
                    draftId={articleData?.draft?._id}
                    refetch={refetchArticle}
                />
                <div>
                    {articleData?.draft?.comments?.map((comment) => {
                        return (
                            <div key={comment._id} style={{ marginBottom: 16 }}>
                                <Typography style={{ display: "flex", alignItems: "center" }}>
                                    {comment.isImportant && <LabelImportantIcon
                                        fontSize={"small"}
                                        style={{ marginRight: 4 }}
                                    />}
                                    {comment.message}
                                </Typography>
                                <Typography style={{ fontSize: 12, color: "rgba(0,0,0,0.72)" }}>
                                    by {comment.createdByUser.firstName} {comment.createdByUser.lastName} - {moment(comment.createdAt).fromNow()}
                                </Typography>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
