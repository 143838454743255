import moment from "moment";
import React from "react";
import { Virtuoso } from "react-virtuoso";

import { IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import CloseIcon from "@material-ui/icons/Close";

import {
    useDeleteNotificationMutation, useNotificationsQuery, useReadNotificationMutation
} from "../../generated/generated-types";
import { useStyles } from "./styles";

interface PopupNotificationsProps {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleMenuClose(): void;
}

export const PopupNotificationMenu: React.FC<PopupNotificationsProps> = (props) => {
    const { anchorEl, open, handleMenuClose } = props;
    const classes = useStyles();

    const { data, refetch } = useNotificationsQuery();

    const [ readNotification ] = useReadNotificationMutation({
        onError: () => {
            //TODO
        },
        onCompleted: async () => {
            await refetch();
        }
    });

    const [ deleteNotification ] = useDeleteNotificationMutation({
        onError: () => {
            //TODO
        },
        onCompleted: async () => {
            await refetch();
        }
    });

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            classes={{
                paper: classes.menuWrapper
            }}
            onClose={handleMenuClose}
            disableScrollLock
        >
            <div className={classes.notificationsHeader}>
                <Typography
                    style={{fontWeight: "bold", display: "flex", justifyContent: "center"}}
                >
                    Notifications
                </Typography>
            </div>
            {data?.notifications &&
                <Virtuoso
                    style={{ width: 400, height: "80vh" }}
                    totalCount={data.notifications.length}
                    item={index => {
                        const notification = data.notifications[index];
                        const isRead = notification.read;

                        //TO:DO handle on click, visit meta data
                        const read = () => readNotification({variables: {
                            id: notification._id
                        }});

                        const deleteNotif = () => deleteNotification({
                            variables: {
                                id: notification._id
                            }
                        });

                        return (
                            <MenuItem
                                onClick={read}
                                style={{
                                    padding: 16,
                                    display: "flex",
                                    alignItems: "start",
                                    backgroundColor: isRead ? undefined : "rgb(247 246 247)",
                                    borderTop: "1px solid lightgrey"
                                }}
                            >
                                <div className={classes.itemContainer}>
                                    <Typography className={classes.description}>
                                        {notification.description}
                                    </Typography>
                                    <div className={classes.timeAgoWrapper}>
                                        <ChatBubbleOutlineIcon
                                            className={classes.timeAgoIcon}
                                        />
                                        <Typography className={classes.timeAgoText}>
                                            {moment(notification.createdAt).fromNow()}
                                        </Typography>
                                    </div>
                                </div>
                                <IconButton
                                    onClick={deleteNotif}
                                    className={classes.closeButtonWrapper}
                                >
                                    <CloseIcon className={classes.closeIcon} />
                                </IconButton>
                            </MenuItem>
                        );
                    }}
                />
            }
        </Menu>
    );
};
