import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";

import { useLoadingBarStore, useSnackbarStore } from "../../..";
import { useSubmissionsQuery, useFlagDraftMutation } from "../../../../generated/generated-types";

export const FlagChangesButton: React.FC<{ draftId?: string, refetchArticle(): void }> = (props) => {
    const { draftId, refetchArticle } = props;

    const { refetch: refetchSubmission } = useSubmissionsQuery();

    const [flagDraft, { loading: flagDraftLoading }] = useFlagDraftMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: async () => {
            closeFlagCommentBox();

            await refetchArticle();
            await refetchSubmission();
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(flagDraftLoading);
    }, [flagDraftLoading, setLoadingStore]);

    const [open, setOpen] = useState(false);
    const openFlagCommentBox = useCallback(
        () => {
            setOpen(true);
        },
        [setOpen],
    );
    const closeFlagCommentBox = useCallback(
        () => {
            setOpen(false);
        },
        [setOpen],
    );

    return (
        <>
            <Button
                color={"primary"}
                style={{ marginRight: 8 }}
                disabled={typeof draftId === "undefined" || flagDraftLoading}
                onClick={openFlagCommentBox}
            >
                Flag for changes
            </Button>
            <Dialog open={open} onClose={closeFlagCommentBox} aria-labelledby="form-dialog-title">
                <Formik
                    initialValues={{ message: "" }}
                    validate={values => {
                        const errors: {
                            message?: string;
                        } = {};

                        if (!values.message) {
                            errors.message = "Required";
                        }

                        return errors;
                    }}
                    onSubmit={async (values) => {
                        if (draftId) {
                            await flagDraft({
                                variables: {
                                    id: draftId,
                                    message: values.message,
                                },
                            });
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        submitForm
                    }) => (
                        <>
                            <DialogTitle id="form-dialog-title">Make Comment</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To flag this article, a comment is required to explain why. This will be communicated to the author to make the necessary changes.
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin={"dense"}
                                    id={"message"}
                                    value={values.message}
                                    label={"Comment"}
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    multiline
                                    helperText={touched.message && errors.message}
                                    error={!!(touched.message && errors.message)}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeFlagCommentBox} color="primary">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={submitForm}
                                    color={"primary"}
                                    disabled={typeof draftId === "undefined" || isSubmitting}
                                >
                                    Flag Article
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
};
