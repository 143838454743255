import React, { FunctionComponent, useEffect } from "react";
import { useRouteMatch } from "react-router";

import { Paper, Typography } from "@material-ui/core";

import { Header, Page, useLoadingBarStore } from "../../components";
import { useActivationQuery } from "../../generated/generated-types";
import { ActivationForm } from "./components";
import { useStyles } from "./styles";

export const ActivationScreen: FunctionComponent = () => {
    const classes = useStyles();
    const match = useRouteMatch<{ id: string }>();
    
    const { data: activationDetails, loading: activationDetailsLoading, error: activationDetailsError } = useActivationQuery({ variables: { id: match.params.id } });
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(activationDetailsLoading);
    }, [activationDetailsLoading, setLoadingStore]);

    return (
        <Page
            maxWidth={"sm"}
            disableDrawerPadding
            header={
                <Header backUrlPath={"/login"} title={"Activate Account"} secondary />
            }
        >
            <Paper className={classes.wrapper}>
                {activationDetailsError
                    ? <Typography>
                        {activationDetailsError.graphQLErrors[0].message}
                    </Typography>
                    : activationDetails?.activationDetails.email
                        ? <ActivationForm
                            email={activationDetails.activationDetails.email}
                            id={match.params.id}
                        />
                        : <></>
                }
            </Paper>
        </Page>
    );
};
