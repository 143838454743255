import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    hero: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        marginBottom: theme.spacing(2)
    },
    formField: {
        marginTop: theme.spacing()
    },
    submitBtn: {
        marginTop: theme.spacing(4)
    }
}));