import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    topCard: {
        flex: 1,
        margin: 8,
        marginTop: 48,
        padding: theme.spacing(),
        paddingBottom: 0,
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
    },
}));