import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: "0 auto",
        backgroundColor: theme.palette.secondary.main,
        marginBottom: theme.spacing(2),
    },
    container: {
        paddingTop: theme.spacing(4)
    },
    hero: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        marginBottom: theme.spacing(2)
    },
    wrapper: {
        padding: theme.spacing(4)
    },
    formField: {
        marginTop: theme.spacing()
    },
    submitBtn: {
        marginTop: theme.spacing(4)
    }
}));