import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    backgroundWrapper: {
        height: 240,
        width: "100vw",
        marginTop: 48,
        background: "linear-gradient(180deg, rgba(103,58,183,1) 0%, rgba(99,54,180,1) 31%, rgba(80,36,157,1) 100%)"
    },
    topWrapper: {
        flex: 1,
        marginTop: 64,
        marginLeft: 16,
        marginRight: 16,
    },
    topCard: {
        flex: 1,
        margin: 8,
        marginTop: 48,
        padding: theme.spacing(),
        paddingBottom: 0,
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
    },
    topContainer: {
        maxWidth: theme.breakpoints.values.lg,
        margin: "0 auto",
        display: "flex",
        flex: 1
    },
    recentActivity: {
        maxWidth: theme.breakpoints.values.lg,
        margin: "0 auto",
        marginTop: 36,
        padding: theme.spacing(2)
    }
}));