import { RoleEnum } from "../generated/generated-types";

function getRole() {
    const role = localStorage.getItem("role"); 
    
    switch (role) {
        case "admin":
            return RoleEnum.Admin;
        case "qa":
            return RoleEnum.Qa;
        default:
            return RoleEnum.Creator;
    }
}

export default getRole;