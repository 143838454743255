/* eslint-disable @typescript-eslint/ban-types */
import { ApolloClient, fromPromise } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { cache } from "./";
import { AccessTokenDocument } from "../../generated/generated-types";
import { link } from "./httpLink";

let isRefreshing = false;
let pendingRequests: Function[] = [];

const setIsRefreshing = (value: boolean) => {
    isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Function) => {
    pendingRequests.push(pendingRequest);
};

const renewTokenApiClient = new ApolloClient({
    link,
    cache,
    credentials: "include",
});

const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback());
    pendingRequests = [];
};

const getNewToken = async () => {
    await renewTokenApiClient.query({
        query: AccessTokenDocument,
        fetchPolicy: "no-cache",
    })!;
};

export const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            switch (err?.message) {
                case "Access denied! You need to be authorized to perform this action!":
                case "Context creation failed: Access token expired":
                    if (!isRefreshing) {
                        setIsRefreshing(true);

                        return fromPromise(
                            getNewToken().catch(() => {
                                resolvePendingRequests();
                                setIsRefreshing(false);

                                localStorage.clear();

                                return forward(operation);
                            }),
                        ).flatMap(() => {
                            resolvePendingRequests();
                            setIsRefreshing(false);

                            return forward(operation);
                        });
                    } else {
                        return fromPromise(
                            new Promise((resolve) => {
                                addPendingRequest(() => resolve());
                            }),
                        ).flatMap(() => {
                            return forward(operation);
                        });
                    }
            }
        }
    }
});
