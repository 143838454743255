import { Formik } from "formik";
import React, { FunctionComponent, useState, Fragment, useEffect } from "react";
import { Browser } from "react-kawaii";

import {
    Button, Paper, TextField, Typography
} from "@material-ui/core";

import { useSendResetCodeMutation } from "../../generated/generated-types";
import { Header, Page, useLoadingBarStore, useSnackbarStore } from "../../components";
import { useStyles } from "./styles";

const { Checkmark } = require("react-checkmark");

export const ForgotPasswordScreen: FunctionComponent = () => {
    const classes = useStyles();

    const [sentActivationEmail, setSentActivationEmail] = useState(false);
    const [reset, { loading: sending, error }] = useSendResetCodeMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: () => {
            setSentActivationEmail(true);
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(sending);
    }, [sending, setLoadingStore]);

    return (
        <Fragment>
            <Page
                maxWidth={"sm"}
                disableDrawerPadding
                header={
                    <Header
                        hideSecondaryButtons
                        backUrlPath={"/login"}
                        title={"Forgot Password"}
                        secondary
                    />
                }
            >
                <Paper className={classes.wrapper}>
                    {sentActivationEmail ? <ForgotPasswordSuccess /> : <Formik
                        initialValues={{
                            email: ""
                        }}
                        validate={values => {
                            const errors: {
                                email?: string;
                            } = {};

                            if (!values.email) {
                                errors.email = "Required";
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = "Invalid email address";
                            }
                        
                            return errors;
                        }}
                        onSubmit={async (values) => {
                            await reset({
                                variables: {
                                    email: values.email
                                },
                            });
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <Fragment>
                                <div className={classes.hero}>
                                    <Browser
                                        size={200}
                                        mood={error ? "ko" : values.email ? "blissful" : "happy"}
                                        color="#61DDBC"
                                    />
                                </div>
                                <Typography align={"center"} variant={"h4"} gutterBottom>
                                    Forgot Password
                                </Typography>
                                <form onSubmit={handleSubmit} style={{marginTop: 32}}>
                                    <TextField
                                        fullWidth
                                        autoFocus
                                        required
                                        autoComplete={"email"}
                                        error={!!errors.email && touched.email}
                                        helperText={errors.email}
                                        label={"Email"}
                                        name={"email"}
                                        variant={"outlined"}
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <div className={classes.submitBtn}>
                                        <Button
                                            fullWidth
                                            variant={"contained"}
                                            color={"primary"}
                                            disabled={isSubmitting}
                                            type={"submit"}
                                        >
                                            Reset Password
                                        </Button>
                                    </div>
                                </form>
                            </Fragment>
                        )}
                    </Formik>
                    }
                </Paper>
            </Page>
        </Fragment>
    );
};

const ForgotPasswordSuccess = () => {
    return (
        <div style={{alignItems: "center", display: "flex", flexDirection: "column"}}>
            <Checkmark size={180} />
            <Typography style={{marginTop: 32}}>
                An email has been sent to reset your account, if it exists.
            </Typography>
        </div>
    );
};

