import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    menuWrapper: {
        position: "absolute",
        marginTop: theme.spacing(3),
    },
    closeButtonWrapper: {
        padding: 14,
        right: theme.spacing(),
        alignSelf: "center"
    },
    closeIcon: {
        fontSize: 14
    },
    timeAgoIcon: {
        fontSize: 14,
        marginRight: theme.spacing(0.5)
    },
    timeAgoText: {
        fontSize: 14,
        color: "#616161"
    },
    timeAgoWrapper: {
        display: "flex",
        alignItems: "center"
    },
    notificationsHeader: {
        padding: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    description: {
        display: "flex",
        marginBottom: 8,
        fontSize: 14,
        width: 340,
        whiteSpace: "initial"
    },
    itemContainer: {
        flexDirection: "column",
    }
}));
