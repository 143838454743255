import React, { FunctionComponent, useEffect, useMemo } from "react";

import { Button } from "@material-ui/core";

import { Header, TopLoadingBar, useLoadingBarStore } from "../../components";
import { RoleEnum, useDashboardQuery } from "../../generated/generated-types";
import { DashboardCard, RecentActivity } from "./components";
import { useStyles } from "./styles";
import getRole from "../../utils/getRole";
import { Link } from "react-router-dom";

export const DashboardScreen: FunctionComponent = () => {
    const classes = useStyles();

    const {
        data: dashboardData,
        loading: dashboardLoading,
    } = useDashboardQuery();

    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(dashboardLoading);
    }, [dashboardLoading, setLoadingStore]);

    const yourContributions = useMemo(() => {
        if (dashboardData?.contributionsCount && dashboardData?.publishedCount) {
            return dashboardData.contributionsCount + dashboardData.publishedCount;
        }
    
        return 0;
    }, [dashboardData?.contributionsCount, dashboardData?.publishedCount]);

    const role = useMemo(() => getRole(), []);
    const canManageParenthub = useMemo(() => role === RoleEnum.Qa || role === RoleEnum.Admin, [role]);

    return (
        <>
            <TopLoadingBar />
            <Header title={"Dashboard"} hideElevation />
            <div className={classes.backgroundWrapper}>
                <div className={classes.topWrapper}>
                    <div className={classes.topContainer}>
                        <DashboardCard
                            button={
                                <Button
                                    component={Link}
                                    to={"/parenthub/manage-submissions"}
                                    size="small"
                                    color="primary"
                                >
                                    Make Changes
                                </Button>
                            }
                            statistic={dashboardData?.flaggedDraftsCount || 0}
                            description={"Articles Need Attention"}
                        />
                        <DashboardCard
                            button={
                                canManageParenthub
                                    ? <Button
                                        size="small"
                                        color="primary"
                                        component={Link}
                                        to={"/parenthub/manage-all"}
                                    >
                                        Manage ParentHub
                                    </Button>
                                    : <Button
                                        size="small"
                                        color="primary"
                                        component={Link}
                                        to={"/parenthub/add-article"}
                                    >
                                        Create Article
                                    </Button>
                            }
                            statistic={dashboardData?.articleCount || 0}
                            description={"ParentHub Articles Live"}
                        />
                        <DashboardCard
                            button={
                                <Button
                                    size="small"
                                    color="primary"
                                    component={Link}
                                    to={"/profile"}
                                >
                                    Your contributions
                                </Button>
                            }
                            statistic={yourContributions}
                            description={"Contributions"}
                        />
                    </div>
                    {dashboardData?.recentActivity &&
                        <RecentActivity
                            recentActivity={dashboardData.recentActivity}
                        />
                    }
                </div>
            </div>
        </>
    );
};
