import React, { FunctionComponent, useEffect } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import { Header, useLoadingBarStore, useSnackbarStore } from "../../components";
import { useUpdatePasswordMutation } from "../../generated/generated-types";

import { useStyles } from "./styles";
import { useHistory } from "react-router";
import { Formik } from "formik";


export const UpdatePasswordScreen: FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();

    const [updatePassword, { loading: updatePasswordLoading }] = useUpdatePasswordMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: () => {
            setSnackbarMessage("Password reset successfully", "success");
            history.goBack();
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(updatePasswordLoading);
    }, [updatePasswordLoading]);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog
            open
            fullWidth
            fullScreen={mobile}
            onClose={history.goBack}
            maxWidth={"md"}
        >
            {mobile ? <Header title={"Update Password"} secondary />
                : <DialogTitle>Update Password</DialogTitle>
            }
            <Formik
                initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                }}
                validate={values => {
                    const errors: {
                        currentPassword?: string;
                        confirmPassword?: string;
                        newPassword?: string;
                    } = {};

                    if (!values.newPassword) {
                        errors.newPassword = "Required";
                    }

                    if (!values.confirmPassword) {
                        errors.confirmPassword = "Required";
                    }

                    if (!values.currentPassword) {
                        errors.currentPassword = "Required";
                    }

                    if (values.confirmPassword !== values.newPassword) {
                        errors.confirmPassword = "Must match new password";
                    }

                    return errors;
                }}
                onSubmit={async (values) => {
                    await updatePassword({
                        variables: {
                            password: values.currentPassword,
                            newPassword: values.newPassword,
                        },
                    });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    submitForm,
                    isSubmitting,
                }) => (
                    <>
                        <DialogContent>
                            <TextField
                                fullWidth
                                autoFocus
                                required
                                type={"password"}
                                autoComplete={"currentPassword"}
                                error={!!errors.currentPassword && touched.currentPassword}
                                helperText={touched.currentPassword && errors.currentPassword}
                                label={"Current Password"}
                                name={"currentPassword"}
                                variant={"outlined"}
                                value={values.currentPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Typography style={{ marginTop: 16, marginBottom: 16 }} gutterBottom>
                                Your password must contain at least 8 characters, 1 number and 1 special character
                            </Typography>
                            <TextField
                                fullWidth
                                required
                                type={"password"}
                                autoComplete={"given-name"}
                                variant={"outlined"}
                                error={!!errors.newPassword && touched.newPassword}
                                helperText={touched.newPassword && errors.newPassword}
                                label={"New Password"}
                                name={"newPassword"}
                                value={values.newPassword}
                                onChange={handleChange}
                                className={classes.formField}
                                onBlur={handleBlur}
                            />
                            <TextField
                                fullWidth
                                required
                                type={"password"}
                                autoComplete={"family-name"}
                                variant={"outlined"}
                                error={!!errors.confirmPassword && touched.confirmPassword}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                label={"Confirm Password"}
                                name={"confirmPassword"}
                                value={values.confirmPassword}
                                onChange={handleChange}
                                className={classes.formField}
                                onBlur={handleBlur}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                disabled={isSubmitting}
                                onClick={submitForm}
                            >
                                Change Password
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );
};
