import moment from "moment";
import React from "react";

import {
    Avatar, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography
} from "@material-ui/core";

import { Log } from "../../../../generated/generated-types";
import { useStyles } from "./styles";

type RecentActivityItem = Pick<Log, "type" | "subDescription" | "description" | "createdAt" | "_id">;

interface RecentActivityProps {
    recentActivity: RecentActivityItem[];
}

export const RecentActivity: React.FC<RecentActivityProps> = (props) => {
    const classes = useStyles();

    const { recentActivity} = props;

    return (
        <div className={classes.recentActivity}>
            <Typography variant={"h5"}>
                Recent Activity
            </Typography>
            <List>
                {recentActivity.map((activity) => (
                    <RecentActivityItem
                        key={activity._id}
                        activity={activity}
                    />
                ))}
            </List>
        </div>
    );
};

const RecentActivityItem: React.FC<{activity: RecentActivityItem}> = (props) => {
    const { activity: { description, subDescription, createdAt, type } } = props;

    const descriptiveType =
        type === "DRAFTSUBMITTED"
            ? "Draft Submitted"
            : type === "PUBLISHED"
                ? "Published Article"
                : type;

    return (
        <ListItem
            alignItems="flex-start"
        >
            <ListItemAvatar>
                <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
            </ListItemAvatar>
            <ListItemText
                primary={descriptiveType}
                secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                        >
                            {description}
                        </Typography>
                        {" — " + subDescription}
                    </React.Fragment>
                }
            />
            <ListItemSecondaryAction>
                <Typography>
                    {moment(createdAt).fromNow()}
                </Typography>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
