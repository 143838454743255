import startcase from "lodash.startcase";
/* eslint-disable react/display-name */
import MaterialTable from "material-table";
import React, { forwardRef, FunctionComponent, useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router";

import Add from "@material-ui/icons/Add";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { Header, Page, useLoadingBarStore, useSnackbarStore } from "../../components";
import { AccountStatus, useAccountsQuery, useDeleteAccountMutation, useSendResetCodeMutation } from "../../generated/generated-types";
import { parseRole } from "../../utils";

const tableIcons = {
    Add: forwardRef((props) => <Add {...props} />),
    Check: forwardRef((props) => <Check {...props} />),
    Clear: forwardRef((props) => <Clear {...props} />),
    Delete: forwardRef((props) => <DeleteOutline {...props} />),
    DetailPanel: forwardRef((props) => <ChevronRight {...props} />),
    Edit: forwardRef((props) => <Edit {...props} />),
    Export: forwardRef((props) => <SaveAlt {...props} />),
    Filter: forwardRef((props) => <FilterList {...props} />),
    FirstPage: forwardRef((props) => <FirstPage {...props} />),
    LastPage: forwardRef((props) => <LastPage {...props} />),
    NextPage: forwardRef((props) => <ChevronRight {...props} />),
    PreviousPage: forwardRef((props) => <ChevronLeft {...props} />),
    ResetSearch: forwardRef((props) => <Clear {...props} />),
    Search: forwardRef((props) => <Search {...props} />),
    SortArrow: forwardRef((props) => <ArrowDownward {...props} />),
    ThirdStateCheck: forwardRef((props) => <Remove {...props} />),
    ViewColumn: forwardRef((props) => <ViewColumn {...props} />)
};

export const UsersScreen: FunctionComponent = () => {
    const { data, loading, refetch } = useAccountsQuery();

    const tableFormat = useMemo(() => ({
        columns: [
            { title: "Email", field: "email" },
            { title: "First Name", field: "firstName" },
            { title: "Surname", field: "lastName" },
            { title: "Role", field: "role" },
            { title: "Status", field: "status", cellStyle: (value: AccountStatus) => ({
                color: value === AccountStatus.Pending
                    ? "#ff6600"
                    : value === AccountStatus.Locked
                        ? "#f44336"
                        : "#009900"
            })},
        ],
        data: data?.accounts?.map((acc) => ({
            firstName: acc.firstName,
            lastName: acc.lastName,
            email: acc.email,
            activated: acc?.passwordHistory && acc.passwordHistory.length > 0,
            role: parseRole(acc.role),
            status: startcase(acc.status),
            id: acc._id,
        })),
    }), [data]);

    const history = useHistory();
    const location = useLocation();

    const [deleteAccount, { loading: deleteAccountLoading }] = useDeleteAccountMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);  
        },
        onCompleted: async () => {
            await refetch();
        }
    });

    const [resetCode, { loading: sendResetCodeLoading }] = useSendResetCodeMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: async () => {
            await refetch();
            setSnackbarMessage("Reset code sent to user", "success");
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(loading || deleteAccountLoading || sendResetCodeLoading);
    }, [loading, setLoadingStore, deleteAccountLoading, sendResetCodeLoading]);

    return (
        <Page
            header={
                <Header title={"Users"} />
            }
        >
            <MaterialTable
                title={"Manage Users"}
                columns={tableFormat.columns as any}
                data={tableFormat.data as any}
                options={
                    {
                        pageSize: 10
                    }
                }
                icons={tableIcons as any}
                actions={[
                    {
                        icon: Edit,
                        tooltip: "Edit User",
                        onClick: (_e, rowData) => {
                            //@ts-ignore
                            history.push(`/manage/users/${rowData.id}/edit`, {
                                background: location.pathname
                            });
                        }
                    },
                    rowData => (
                        //@ts-ignore
                        !rowData.activated
                            ? {
                                icon: SendIcon,
                                tooltip: "Resend Activation Email",
                                onClick: () => {
                                    
                                }
                            }
                            :
                            {
                                icon: LockOpenIcon,
                                tooltip: "Reset Password",
                                onClick: (_e, rowData) => {
                                    resetCode({
                                        variables: {
                                            //@ts-ignore
                                            email: rowData.email
                                        }
                                    });
                                }
                            }
                    ),
                    {
                        icon: DeleteOutline,
                        tooltip: "Delete User",
                        onClick: (_e, rowData) => {
                            deleteAccount({
                                variables: {
                                    //@ts-ignore
                                    email: rowData!.email
                                }
                            });
                        }
                    },
                    {
                        icon: PersonAddIcon,
                        tooltip: "Add User",
                        onClick: () => {
                            history.push("/manage/users/add", {
                                background: location.pathname
                            });
                        },
                        isFreeAction: true
                    }
                ]}
            />
        </Page>
    );
};
