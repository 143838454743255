import clsx from "clsx";
import React, { FunctionComponent } from "react";

import { Container, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

import { useDrawerStore } from "../../components/Drawer";
import { TopLoadingBar } from "../TopLoadingBar";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        // padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
}));

interface Props {
    header: JSX.Element,
    maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
    disableDrawerPadding?: boolean;
}

export const Page: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const open = useDrawerStore(state => state.open);

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("lg"));

    const { disableDrawerPadding } = props;

    return (
        <>
            <TopLoadingBar />
            {props.header}
            <Container
                className={desktop && !open ? clsx(classes.content, {
                    [classes.contentShift]: open,
                }) : undefined}
                maxWidth={props.maxWidth}
            >
                {!disableDrawerPadding && <div className={classes.drawerHeader} />}
                {props.children as any}
            </Container>
        </>
    );
};
