import React, { FunctionComponent, useEffect, useState } from "react";

import { Typography } from "@material-ui/core";

import { Header, Page, ParentHubBubble, useLoadingBarStore } from "../../components";
import { useHubsTagsQuery } from "../../generated/generated-types";
import { AddArticleForm } from "./components";
import { useStyles } from "./styles";

export const AddArticleScreen: FunctionComponent = () => {
    const classes = useStyles();

    const { data: hubsData, loading: loadingHubTags } = useHubsTagsQuery();
    const [selectedHubId, setSelectedHubId] = useState<string | undefined>(undefined);

    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(loadingHubTags);
    }, [loadingHubTags]);

    return (
        <Page
            header={
                <Header title={"Add Article"} />
            }
        >
            <Typography className={classes.selectHubHeader}>
                Select a Hub
            </Typography>
            <div className={classes.root}>
                {hubsData?.hubs.map((hub, index) =>
                    <ParentHubBubble
                        key={`select_hub-${index}`}
                        name={hub.name}
                        selected={selectedHubId === hub._id}
                        image={hub.image}
                        id={hub._id}
                        onClick={() => setSelectedHubId(hub._id)}
                    />
                )}
            </div>
            {selectedHubId && hubsData?.tags &&
                <AddArticleForm
                    tags={hubsData?.tags}
                    selectedHubId={selectedHubId}
                />
            }
        </Page>
    );
};
