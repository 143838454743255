import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        overflow: "auto",
        marginBottom: 16,
    },
    header: {
        marginTop: theme.spacing(2),
        color: "#757575"
    },
    previewModeTopBar: {
        display: "flex",
        marginBottom: 16,
        justifyContent: "space-between"
    },
    chip: {
        marginRight: 4,
        marginBottom: 4,
        marginTop: theme.spacing()
    },
    previewModeContainer: {
        flex: 1,
        [theme.breakpoints.up("md")]: {
            width: 800
        }
    }
}));
