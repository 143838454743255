import React, { FunctionComponent, useEffect, useState } from "react";

import { Dialog, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import { Header, ParentHubBubble, useLoadingBarStore } from "../../components";
import { useArticleQuery, useHubsTagsQuery } from "../../generated/generated-types";
import { EditDraftForm } from "./components";
import { useStyles } from "./styles";
import { useHistory, useRouteMatch } from "react-router";


export const EditDraftScreen: FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();

    const match = useRouteMatch<{id: string}>();
    const articleId = match.params.id;

    const { data: articleData, refetch } = useArticleQuery({variables: {id: articleId}});
    const { data: hubsData, loading: loadingHubTags } = useHubsTagsQuery();
    const [selectedHubId, setSelectedHubId] = useState<string | undefined>(undefined);

    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(loadingHubTags);
    }, [loadingHubTags]);

    useEffect(() => {
        if (articleData?.draft?.hub) {
            setSelectedHubId(articleData.draft.hub);
        }
    }, [articleData]);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog
            open
            fullWidth
            fullScreen={mobile}
            onClose={history.goBack}
            maxWidth={"lg"}
        >
            {mobile ? <Header title={"Edit Draft"} secondary />
                : <DialogTitle>Edit Draft</DialogTitle>
            }
            <DialogContent>
                <Typography className={classes.selectHubHeader}>
                    Select a Hub
                </Typography>
                <div className={classes.root}>
                    {hubsData?.hubs.map((hub, index) =>
                        <ParentHubBubble
                            key={`select_hub-${index}`}
                            name={hub.name}
                            selected={selectedHubId === hub._id}
                            image={hub.image}
                            id={hub._id}
                            onClick={() => setSelectedHubId(hub._id)}
                        />
                    )}
                </div>
                {selectedHubId && hubsData?.tags && articleData?.draft &&
                    <EditDraftForm
                        tags={hubsData?.tags}
                        selectedHubId={selectedHubId}
                        draft={articleData.draft}
                        refetchArticle={refetch}
                    />
                }
            </DialogContent>
        </Dialog>
    );
};
