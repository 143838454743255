import React from "react";
import isLoggedIn from "../utils/isLoggedIn";
import { Redirect, useLocation } from "react-router";
import { LoggedInRoute } from "./LoggedInRoute";

interface Props {
    component: React.ElementType;
    path: string;
    exact?: boolean;
    isModal?: boolean;
}

export const PrivateRoute: React.FC<Props> = (props) => {
    const loggedIn = isLoggedIn();
    const location = useLocation();

    if (!loggedIn) {
        return (
            <Redirect to={{
                pathname: "/login",
                state: { from: location }
            }} />
        );
    }

    return (
        <LoggedInRoute {...props} />
    );
};
