import React, { FunctionComponent } from "react";

import {
    Paper, useTheme, useMediaQuery, Dialog, DialogContent, makeStyles
} from "@material-ui/core";

import { Header, Page, PreviewDraft, SubmissionList } from "../../components";
import { useRouteMatch } from "react-router";

const useStyles = makeStyles((theme) => ({
    paperRoot: {
        marginTop: theme.spacing(2),
        marginLeft: -32,
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(4)
    },
}));

export const ManageArticles: FunctionComponent = () => {
    const match = useRouteMatch<{ id: string }>();
    const hasArticleSelected = !!match.params.id;

    const theme = useTheme();
    const classes = useStyles();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Page
            maxWidth={hasArticleSelected ? "lg" : "md"}
            header={
                <Header title={"My Submissions"} />
            }
        >
            <div style={{
                display: "flex", flex: 1,
                minHeight: hasArticleSelected ? "100vh" : undefined,
                marginTop: hasArticleSelected ? -16 : undefined,
            }}>
                {hasArticleSelected
                    ? <Paper square elevation={2} style={{ zIndex: 2 }}>
                        <SubmissionList expanded={hasArticleSelected} />
                    </Paper>
                    : <SubmissionList expanded={hasArticleSelected} />
                }
                {hasArticleSelected
                    ? mobile
                        ? <Dialog
                            fullScreen={mobile}
                            maxWidth={"lg"}
                            open={true}
                        >
                            <DialogContent>
                                <Header title={"Preview Article"} secondary />
                                <PreviewDraft
                                    id={match.params.id}
                                />
                            </DialogContent>
                        </Dialog>
                        : <Paper className={classes.paperRoot}>
                            <PreviewDraft
                                id={match.params.id}
                            />
                        </Paper>
                    : undefined
                }
            </div>
        </Page>
    );
};
