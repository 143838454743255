import React, { FunctionComponent, useEffect } from "react";
import {
    Snackbar
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import create from "zustand";
import { combine } from "zustand/middleware";

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const useSnackbarStore = create(combine({
    message: "",
    open: false,
    variant: "",
}, set => ({
    setSnackbarMessage: (message: string, variant?: AlertProps["severity"]) => set({ message, open: true, variant: variant ?? "error" }),
    closeSnackbar: () => set({ open: false }),
    openSnackbar: () => set({ open: true }),
})));

export const CCSnackbar: FunctionComponent = () => {
    const { closeSnackbar, message, variant, open, openSnackbar } = useSnackbarStore(state => (
        {
            variant: state.variant,
            open: state.open,
            message: state.message,
            openSnackbar: state.openSnackbar,
            closeSnackbar: state.closeSnackbar
        })
    );

    useEffect(() => {
        if (message.length > 0) {
            openSnackbar();
        }
    }, [message, openSnackbar]);

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={closeSnackbar}
        >
            <Alert
                severity={variant as AlertProps["severity"]}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};
