import React, { useCallback, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";


export const ApproveButton: React.FC<{ approve(): void }> = (props) => {
    const { approve } = props;

    const [open, setOpen] = useState(false);
    const openFlagCommentBox = useCallback(
        () => {
            setOpen(true);
        },
        [setOpen],
    );
    const closeFlagCommentBox = useCallback(
        () => {
            setOpen(false);
        },
        [setOpen],
    );

    return (
        <>
            <Button
                color={"primary"}
                variant={"contained"}
                style={{ marginRight: 8 }}
                onClick={openFlagCommentBox}
            >
                Approve
            </Button>
            <Dialog open={open} onClose={closeFlagCommentBox} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete Article</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Approving this article will delete it, do you wish to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeFlagCommentBox} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={approve}
                        color={"primary"}
                    >
                        Delete Article
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
