import { Formik } from "formik";
import React, { useEffect } from "react";

import { IconButton, TextField } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

import { useLoadingBarStore, useSnackbarStore } from "../../..";
import { useCommentDraftMutation } from "../../../../generated/generated-types";

export const AddCommentField: React.FC<{ draftId?: string, refetch(): void }> = (props) => {
    const { draftId, refetch } = props;

    const [commentDraft, { loading: commentDraftLoading }] = useCommentDraftMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: async () => {
            await refetch();
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(commentDraftLoading);
    }, [commentDraftLoading, setLoadingStore]);

    return (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
            <Formik
                initialValues={{ message: "" }}
                validate={values => {
                    const errors: {
                        message?: string;
                    } = {};

                    if (!values.message) {
                        errors.message = "Required";
                    }

                    return errors;
                }}
                onSubmit={async (values, { resetForm }) => {
                    if (draftId) {
                        await commentDraft({
                            variables: {
                                id: draftId,
                                message: values.message,
                            },
                        });

                        resetForm();
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    submitForm
                }) => (
                    <>
                        <TextField
                            value={values.message}
                            variant={"outlined"}
                            label={"Add comment"}
                            name={"message"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                            error={!!(touched.message && errors.message)}
                            fullWidth
                        />
                        <IconButton
                            edge={"end"}
                            onClick={submitForm}
                            style={{ height: 48 }}
                            color={"primary"}
                            disabled={isSubmitting}
                        >
                            <SendIcon />
                        </IconButton>
                    </>
                )}
            </Formik>
        </div>
    );
};