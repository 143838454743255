import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

// https://www.materialpalette.com/deep-purple/purple
export const ccTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#673AB7",
        },
        secondary: {
            main: "#E040FB"
        },
        // error: {
        //     main: theme.brandDanger
        // },
        text: {
            primary: "#212121",
            secondary: "#757575"
        },
        background: {
            default: "#f6f6f6"
        }
    },
});