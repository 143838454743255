import { Formik } from "formik";
import React, { FunctionComponent, Fragment, useEffect } from "react";
import { Browser } from "react-kawaii";

import {
    Button, Paper, TextField, Typography} from "@material-ui/core";

import { useResetMutation, useResetDetailsQuery } from "../../generated/generated-types";
import { useHistory, useRouteMatch } from "react-router";
import { Header, Page, useLoadingBarStore, useSnackbarStore } from "../../components";
import { useStyles } from "./styles";
import { setLoggedIn } from "../../utils";

export const ResetPasswordScreen: FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch<{ id: string }>();

    const {
        data: resetPasswordDetails,
        loading: resetPasswordDetailsLoading,
        error: resetPasswordDetailsError
    } = useResetDetailsQuery({ variables: { id: match.params.id } });

    const [reset, { error }] = useResetMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: (res) => {
            if (res.reset) {
                setLoggedIn(res.reset._id, res.reset.role);
                
                history.push("/dashboard");
            }
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(resetPasswordDetailsLoading);
    }, [resetPasswordDetailsLoading, setLoadingStore]);

    return (
        <Page
            maxWidth={"sm"}
            disableDrawerPadding
            header={
                <Header
                    hideSecondaryButtons
                    backUrlPath={"/login"}
                    title={"Reset Password"}
                    secondary
                />
            }
        >
            <Paper className={classes.wrapper}>
                {resetPasswordDetailsError ? <>
                    <Typography>
                        {resetPasswordDetailsError.graphQLErrors[0].message}
                    </Typography>
                </>
                    :
                    resetPasswordDetails?.resetDetails.email ? <Formik
                        initialValues={{
                            email: resetPasswordDetails?.resetDetails.email,
                            password: "",
                            confirmPassword: "",
                        }}
                        validate={values => {
                            const errors: {
                                password?: string;
                                confirmPassword?: string;
                            } = {};

                            if (!values.password) {
                                errors.password = "Required";
                            }

                            if (!values.confirmPassword) {
                                errors.confirmPassword = "Required";
                            }

                            if (!values.password) {
                                errors.password = "Required";
                            }

                            if (!values.confirmPassword) {
                                errors.confirmPassword = "Required";
                            }

                            if (values.password !== values.confirmPassword) {
                                errors.confirmPassword = "Password's don't match";
                            }

                            return errors;
                        }}
                        onSubmit={async (values) => {
                            await reset({
                                variables: {
                                    id: match.params.id,
                                    password: values.password,
                                },
                            });
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <Fragment>
                                <div className={classes.hero}>
                                    <Browser
                                        size={200}
                                        mood={error ? "ko" : values.password ? "blissful" : "happy"}
                                        color="#61DDBC"
                                    />
                                </div>
                                <Typography align={"center"} variant={"h4"} gutterBottom>
                                    Reset Password
                                </Typography>
                                <Typography align={"center"} style={{marginBottom: 16}} gutterBottom>
                                    Your password must contain at least 8 characters, 1 number and 1 special character
                                </Typography>
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        fullWidth
                                        autoFocus
                                        required
                                        autoComplete={"email"}
                                        error={!!errors.email && touched.email}
                                        helperText={errors.email}
                                        label={"Email"}
                                        name={"email"}
                                        disabled
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{marginBottom: 8}}
                                    />
                                    <TextField
                                        fullWidth
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label={"Password"}
                                        type={"password"}
                                        name={"password"}
                                        className={classes.formField}
                                        variant={"outlined"}
                                        required
                                        value={values.password}
                                        error={!!errors.password && touched.password}
                                        helperText={touched.password && errors.password}
                                    />
                                    <TextField
                                        fullWidth
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label={"Confirm Password"}
                                        type={"password"}
                                        name={"confirmPassword"}
                                        className={classes.formField}
                                        variant={"outlined"}
                                        required
                                        value={values.confirmPassword}
                                        error={!!errors.confirmPassword && touched.confirmPassword}
                                        helperText={touched.confirmPassword && errors.confirmPassword}
                                    />

                                    <div className={classes.submitBtn}>
                                        <Button
                                            fullWidth
                                            variant={"contained"}
                                            color={"primary"}
                                            disabled={isSubmitting}
                                            type={"submit"}
                                        >
                                            Reset Password
                                        </Button>
                                    </div>
                                </form>
                            </Fragment>
                        )}
                    </Formik>
                        : undefined}
            </Paper>
        </Page>
    );
};