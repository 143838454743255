import { makeStyles, useTheme, Avatar, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    hubBubble: {
        marginRight: theme.spacing(3),
    },
    hubBubbleSelectable: {
        cursor: "pointer",
    },
    hubBubbleName: {
        maxWidth: 104,
        textAlign: "center",
        marginTop: 8,
        fontWeight: "bold",
        userSelect: "none"
    },
    hubImageWrapper: {
        overflow: "hidden",
        borderWidth: 2,
        borderRadius: 300,
        padding: 2,
        borderStyle: "solid",
    },
}));

interface ParentHubBubbleProps {
    id: string;
    image: string;
    name: string;
    selected?: boolean;
    onClick?: () => void;
}

export const ParentHubBubble: FunctionComponent<ParentHubBubbleProps> = (props) => {
    const { name, image, onClick, selected } = props;

    const classes = useStyles();
    const theme = useTheme();

    return (
        <div
            className={clsx(
                classes.hubBubble,
                onClick !== undefined
                    ? classes.hubBubbleSelectable
                    : undefined
            )}
            onClick={onClick}
        >
            <div
                className={classes.hubImageWrapper}
                style={{ borderColor: selected ? theme.palette.primary.main : "#fff" }}
            >
                <Avatar
                    src={image}
                    className={classes.large}
                />
            </div>
            <Typography
                className={classes.hubBubbleName}
            >
                {name}
            </Typography>
        </div>
    );
};
