import moment from "moment";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import {
    Button, Card, CardActions, CardContent, IconButton, Portal, Typography, Zoom
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useNewDraftSubscription, useFlaggedDraftSubscription, usePublishedArticleSubscription, useNotificationsQuery, useDashboardQuery, useSubmissionsQuery } from "../../generated/generated-types";

interface Notification {
    message: string;
    date: string;
    id: string;
    title: string;
    button: JSX.Element;
}

export const OverlayNotifications: FunctionComponent = () => {
    const { data: newDraftData } = useNewDraftSubscription();
    const { data: publishedArticleData } = usePublishedArticleSubscription();
    const { data: flaggedDraftData } = useFlaggedDraftSubscription();

    const { refetch: refetchMySubmissions } = useSubmissionsQuery();
    const { refetch: refetchRecentActivity } = useDashboardQuery();
    const { refetch: refetchInAppNotifications } = useNotificationsQuery();

    const [notifications, setNotifications] = useState<Notification[]>([]);
    const history = useHistory();

    const clearNotification = useCallback(
        (id: string) => {
            const newNotifications = notifications.filter((n) => n.id !== id);
            setNotifications(newNotifications);
        },
        [notifications],
    );

    const refetchAllArticles = useCallback(async () => {
        await refetchMySubmissions();
        await refetchRecentActivity();
        await refetchInAppNotifications();
    }, []);

    // Review draft
    useEffect(() => {
        if (newDraftData?.newDraft) {
            const notification: Notification = {
                message: "A new draft has been posted for review",
                date: newDraftData.newDraft.date,
                id: newDraftData.newDraft.draft._id,
                title: "New draft ready to review",
                button: (
                    <Button color={"primary"}
                        onClick={() => {
                            clearNotification(newDraftData.newDraft.draft._id);
                            history.push(`/parenthub/review-submissions/${newDraftData.newDraft.draft._id}`);
                        }}>
                        Review Draft
                    </Button>
                )
            };

            setNotifications([...notifications, notification]);
        }
    }, [newDraftData]);

    // Flagged draft
    useEffect(() => {
        if (flaggedDraftData?.flaggedDraft) {
            const notification: Notification = {
                message: "Your draft has been flagged for review",
                date: flaggedDraftData.flaggedDraft.date,
                id: flaggedDraftData.flaggedDraft.draft._id,
                title: "Draft needs attention",
                button: (
                    <Button color={"primary"}
                        onClick={() => {
                            clearNotification(flaggedDraftData.flaggedDraft.draft._id);

                            history.push(`/parenthub/manage-submissions/${flaggedDraftData.flaggedDraft.draft._id}`);
                        }}>
                        Review Draft
                    </Button>
                )
            };

            setNotifications([...notifications, notification]);
        }
    }, [flaggedDraftData]);

    // Published article
    useEffect(() => {
        if (publishedArticleData?.publishedArticle) {
            const notification: Notification = {
                message: "Your article has been published",
                date: publishedArticleData?.publishedArticle.date,
                title: "Article published",
                id: publishedArticleData?.publishedArticle.article._id,
                button: (
                    <Button color={"primary"}
                        onClick={() => {
                            clearNotification(publishedArticleData?.publishedArticle.article._id);
                            
                            //TO:DO visit link
                            // history.push(`/parenthub/review-submissions/${newDraftData.newDraft.draft._id}`);
                        }}>
                        View Article
                    </Button>
                )
            };

            setNotifications([...notifications, notification]);
        }
    }, [publishedArticleData]);

    // Other parts of the application that rely on stuff to be refetched
    useEffect(() => {
        refetchAllArticles();
    }, [publishedArticleData, newDraftData]);

    return (
        <Portal>
            <div style={{ position: "fixed", width: 360, top: 64, right: 16, zIndex: 9000 }}
            >
                {notifications && notifications.map((notification) =>
                    <NotificationCard
                        key={notification.id}
                        clearNotification={clearNotification}
                        message={notification.message}
                        date={notification.date}
                        button={notification.button}
                        title={notification.title}
                        id={notification.id}
                    />
                )}
            </div>
        </Portal>
    );
};

const NotificationCard: FunctionComponent<Notification & { clearNotification(date: string): void }> = (props) => {
    const { message, date, title, id } = props;

    const clear = useCallback(
        () => {
            props.clearNotification(id);
        },
        [props.id, props.clearNotification],
    );

    const [fromNow, setFromNow] = useState(moment(date).fromNow());
    useEffect(() => {
        const interval = setInterval(() => {
            setFromNow(moment(date).fromNow());
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [date]);

    return (
        <Zoom in>
            <Card
                elevation={3}
                style={{ marginBottom: 8 }}
            >
                <CardContent>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography style={{ fontWeight: "bold" }}>
                            {title}
                        </Typography>
                        <IconButton
                            onClick={clear}
                            style={{ marginTop: -14, marginRight: -12 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Typography>
                        {message} {fromNow}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={clear}>
                        Close
                    </Button>
                    {props.button}
                </CardActions>
            </Card>
        </Zoom>
    );
};
