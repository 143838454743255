import { Formik } from "formik";
import React, { FunctionComponent, Fragment, useEffect } from "react";
import { Browser } from "react-kawaii";

import {
    Button, makeStyles, Paper, TextField, Typography} from "@material-ui/core";

import { useLoginMutation } from "../../generated/generated-types";
import { useHistory } from "react-router";
import { Page, Header, useLoadingBarStore, useSnackbarStore } from "../../components";
import { NavLink } from "react-router-dom";
import { setLoggedIn } from "../../utils";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: "0 auto",
        backgroundColor: theme.palette.secondary.main,
        marginBottom: theme.spacing(2),
    },
    container: {
        paddingTop: 32
    },
    hero: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        marginBottom: 16
    }
}));

export const LoginScreen: FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();

    const [login, { loading: loggingIn, error }] = useLoginMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: (res) => {
            if (res.login) {
                setLoggedIn(res.login._id, res.login.role);

                history.push("/dashboard");
            }
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(loggingIn);
    }, [loggingIn, setLoadingStore]);

    return (
        <Page
            maxWidth={"sm"}
            header={
                <Header
                    hideMenuButton
                    hideSecondaryButtons
                    title={"Content Creator"}
                />
            }
        >
            <Paper style={{ padding: 32 }}>
                <Formik
                    initialValues={{ email: "", password: "" }}
                    validate={values => {
                        const errors: {
                            email?: string;
                        } = {};
                        
                        if (!values.email) {
                            errors.email = "Required";
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = "Invalid email address";
                        }

                        return errors;
                    }}
                    onSubmit={async (values) => {
                        await login({
                            variables: {
                                email: values.email,
                                password: values.password,
                            },
                        });
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <Fragment>
                            <div className={classes.hero}>
                                <Browser
                                    size={200}
                                    mood={error ? "ko" : values.password ? "blissful" : "happy"}
                                    color="#61DDBC"
                                />
                            </div>
                            <Typography align={"center"} variant={"h4"} gutterBottom>
                                Sign in
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    required
                                    autoComplete={"email"}
                                    variant={"outlined"}
                                    error={!!errors.email && touched.email}
                                    helperText={errors.email}
                                    label={"Email"}
                                    name={"email"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextField
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={"Password"}
                                    type={"password"}
                                    name={"password"}
                                    style={{ marginTop: 8 }}
                                    variant={"outlined"}
                                    required
                                    value={values.password}
                                    error={!!errors.password && touched.password}
                                    helperText={errors.password}
                                />

                                <div style={{ marginTop: 32 }}>
                                    <Button
                                        fullWidth
                                        variant={"contained"}
                                        color={"primary"}
                                        disabled={isSubmitting}
                                        type={"submit"}
                                    >
                                        Login
                                    </Button>
                                    <NavLink to={"/forgot-password"}>
                                        <Typography
                                            style={{ marginTop: 16 }}
                                            align={"center"}
                                        >

                                            Forgot Password?
                                        </Typography>
                                    </NavLink>
                                </div>
                            </form>
                        </Fragment>
                    )}
                </Formik>
            </Paper>
        </Page>
    );
};