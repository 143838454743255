import React, { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import create from "zustand";
import { combine } from "zustand/middleware";

import {
    Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader,
    Typography, useMediaQuery, useTheme
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DescriptionIcon from "@material-ui/icons/Description";
import GavelIcon from "@material-ui/icons/Gavel";
import ListAltIcon from "@material-ui/icons/ListAlt";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import PeopleIcon from "@material-ui/icons/People";
import RateReviewIcon from "@material-ui/icons/RateReview";
import TimelineIcon from "@material-ui/icons/Timeline";

import { useStyles } from "./styles";
import { RoleEnum } from "../../generated/generated-types";
import getRole from "../../utils/getRole";

export const useDrawerStore = create(combine({
    open: false,
}, set => ({
    openDrawer: () => set({ open: true }),
    closeDrawer: () => set({ open: false }),
    toggleDrawer: () => set(state => ({ open: !state.open })),
})));

interface NavItem {
    name: string;
    link: string;
    icon: any;
    role?: RoleEnum[];
}

const navigation: { [key: string]: NavItem[] } = {
    "Dashboard": [
        {
            name: "Dashboard",
            link: "/dashboard",
            icon: TimelineIcon,
        }
    ],
    "ParentHub": [
        {
            name: "Add Article",
            link: "/parenthub/add-article",
            icon: DescriptionIcon,
        },
        {
            name: "My Submissions",
            link: "/parenthub/manage-submissions",
            icon: ListAltIcon,
        }
    ],
    "Quality Assurance": [
        {
            name: "Review Articles",
            link: "/parenthub/review-submissions",
            icon: RateReviewIcon,
            role: [RoleEnum.Qa, RoleEnum.Admin],
        },
        {
            name: "Manage ParentHub",
            link: "/parenthub/manage-all",
            icon: OfflineBoltIcon,
            role: [RoleEnum.Qa, RoleEnum.Admin],
        },
    ],
    "Administration": [
        {
            name: "Review Requests",
            link: "/parenthub/review-requests",
            icon: GavelIcon,
            role: [RoleEnum.Admin],
        },
        {
            name: "Manage Users",
            link: "/manage/users",
            icon: PeopleIcon,
            role: [RoleEnum.Admin],
        }
    ],
};

export const CCDrawer = () => {
    const { closeDrawer, open } = useDrawerStore(state => (
        { open: state.open, closeDrawer: state.closeDrawer }));

    const classes = useStyles();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("lg"));
    const location = useLocation();

    const role = useMemo(() => getRole(), []);

    return (
        <Drawer
            className={open ? classes.drawer : undefined}
            variant={desktop ? "persistent" : "temporary"}
            anchor={"left"}
            open={open}
            onClose={closeDrawer}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <Typography style={{ paddingLeft: 16 }}>Content Creator</Typography>
                {desktop &&
                    <IconButton onClick={closeDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                }
            </div>
            <Divider />

            {Object.entries(navigation).map(([listHeader, items]) => {
                if (items.filter((item) => !item.role || item.role?.includes(role)).length === 0) {
                    return <></>;
                }

                return (
                    <List
                        key={listHeader}
                        component={"nav"}
                        aria-labelledby={"nested-list-subheader"}
                        subheader={
                            <ListSubheader component={"div"} id={"nested-list-subheader"}>
                                {listHeader}
                            </ListSubheader>
                        }
                        className={classes.root}
                    >
                        {items.map((item, index) => {
                            if (item.role && !item.role?.includes(role)) {
                                return <></>;
                            }

                            return (
                                <ListItem
                                    key={`${listHeader}-${index}`}
                                    button
                                    selected={location.pathname.includes(item.link)}
                                    component={NavLink}
                                    to={item.link}
                                    onClick={!desktop ? closeDrawer : undefined}
                                >
                                    <ListItemIcon>
                                        <item.icon />
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                </ListItem>
                            );
                        })}
                    </List>
                );
            })}
        </Drawer>
    );
};
