import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { useLoadingBarStore, useSnackbarStore } from "../../..";
import { useDeleteDraftMutation } from "../../../../generated/generated-types";

export const DeleteButton: React.FC<{ draftId?: string, refetch(): void }> = (props) => {
    const { draftId, refetch } = props;

    const history = useHistory();

    const [deleteDraft, { loading: deleteDraftLoading }] = useDeleteDraftMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: async () => {
            await refetch();

            history.replace("/parenthub/manage-submissions");
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(deleteDraftLoading);
    }, [setLoadingStore, deleteDraftLoading]);

    const [openDeleteConfirmBox, setOpenDeleteConfirmBox] = useState(false);
    const handleOpenPopup = useCallback(
        () => {
            setOpenDeleteConfirmBox(true);
        },
        [setOpenDeleteConfirmBox],
    );
    const handleClosePopup = useCallback(
        () => {
            setOpenDeleteConfirmBox(false);
        },
        [setOpenDeleteConfirmBox],
    );

    return (
        <>
            <IconButton onClick={handleOpenPopup}>
                <DeleteForeverIcon color={"error"} />
            </IconButton>
            <Dialog
                open={openDeleteConfirmBox}
                onClose={handleClosePopup}
                fullWidth
            >
                <Formik
                    initialValues={{ message: "" }}
                    onSubmit={async () => {
                        if (draftId) {
                            await deleteDraft({
                                variables: {
                                    id: draftId,
                                },
                            });
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        submitForm
                    }) => (
                        <>
                            <DialogTitle>Delete Draft</DialogTitle>
                            <DialogContent>
                                <Typography gutterBottom>
                                    Type "DELETE" to confirm deleting this draft
                                </Typography>
                                <TextField
                                    value={values.message}
                                    variant={"standard"}
                                    name={"message"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    error={!!(touched.message && errors.message)}
                                    fullWidth
                                />
                                
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClosePopup} color="primary">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={submitForm}
                                    color={"primary"}
                                    disabled={typeof draftId === "undefined" || isSubmitting || values.message.toLowerCase() !== "delete"}
                                >
                                    Delete Draft
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
};
