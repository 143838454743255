import { Formik } from "formik";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router";

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl, InputLabel, makeStyles, MenuItem, Select, TextField,
    useMediaQuery, useTheme} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

import { Header, useSnackbarStore } from "../../components";
import {
    useCreateAccountMutation, useRolesQuery, useAccountsQuery
} from "../../generated/generated-types";
import { parseRole } from "../../utils";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(0),
        width: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    dialog: {
        width: "100%",
    }
}));

export const AddUserScreen: FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { data } = useRolesQuery();
    const { refetch } = useAccountsQuery();

    const [createAccount] = useCreateAccountMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: async () => {
            await refetch();
            history.goBack();
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);

    return (
        <>
            <Dialog
                fullScreen={mobile}
                maxWidth={"lg"}
                open={true}
                className={classes.dialog}
            >
                <Formik
                    initialValues={{ email: "", role: "" }}
                    validate={values => {
                        const errors: {
                            email?: string;
                            role?: string;
                        } = {};

                        if (!values.email) {
                            errors.email = "Required";
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = "Invalid email address";
                        }

                        if (!values.role) {
                            errors.role = "Must select a role";
                        }

                        return errors;
                    }}
                    onSubmit={async (values) => {
                        await createAccount({
                            variables: {
                                email: values.email,
                                role: values.role,
                            },
                        });
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        submitForm,
                        isSubmitting,
                    }) => {
                        return (
                            <>
                                {mobile
                                    ? <Header title={"Add User"} secondary />
                                    : <DialogTitle>Add User</DialogTitle>
                                }
                                <DialogContent>
                                    <DialogContentText>
                                        An email will be sent to the user to activate their account
                                    </DialogContentText>

                                    <TextField
                                        autoFocus
                                        margin={"dense"}
                                        id={"email"}
                                        label={"Email Address"}
                                        type={"email"}
                                        required
                                        name={"email"}
                                        error={!!errors.email && touched.email}
                                        helperText={errors.email}
                                        fullWidth
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{ marginBottom: 16 }}
                                    />
                                    <FormControl
                                        required
                                        className={classes.formControl}
                                        error={!!errors.role && touched.role}
                                        style={{ marginBottom: 32 }}
                                    >
                                        <InputLabel id={"select-role"}>Assign Role</InputLabel>
                                        <Select
                                            labelId={"select-role-label"}
                                            id={"select-role"}
                                            value={values.role}
                                            name={"role"}
                                            onChange={handleChange}
                                        >
                                            {data?.roles && data?.roles.map((role) =>
                                                <MenuItem key={role.role} value={role.role}>{parseRole(role.role)}</MenuItem>)
                                            }
                                        </Select>
                                        {!!errors.role && touched.role && <FormHelperText error>{errors.role}</FormHelperText>}
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button color={"primary"} onClick={history.goBack}>
                                        Cancel
                                    </Button>
                                    <Button color={"primary"}
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Register
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }
                    }
                </Formik>
            </Dialog>
        </>
    );
};
