import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Header, useSnackbarStore } from "..";
import { useParenthubArticleQuery, useMakeDeleteArticleRequestMutation, useMyRequestsQuery } from "../../generated/generated-types";
import { markedDownContent } from "../../utils/markedDownContent";

const dompurify = require("dompurify");

interface PreviewArticleProps {
    articleId: string;
}

export const PreviewArticle: React.FC<PreviewArticleProps> = (props) => {
    const { articleId } = props;

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const history = useHistory();

    const { data: articleData, loading: articleLoading, refetch: refetchArticle } = useParenthubArticleQuery({
        variables: {
            id: articleId,
        }
    });

    const { refetch: refetchMyRequests } = useMyRequestsQuery();

    const goBack = useCallback(
        () => {
            history.goBack();
        },
        [history],
    );

    const [ deleteArticleRequest ] = useMakeDeleteArticleRequestMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: async () => {
            await refetchMyRequests();
            await refetchArticle();
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);

    return (
        <Dialog
            fullScreen={mobile}
            fullWidth
            open
            onClose={goBack}
        >
            {mobile && <Header title={"Preview Article"} secondary />}
            <img src={articleData?.article.image} style={{maxHeight: 300, objectFit: "cover"}} />
            <DialogTitle>
                {articleData?.article.title}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {articleData?.article?.content &&
                        <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(markedDownContent(articleData?.article?.content)) }} />
                    }
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    color={"primary"}
                    disabled={articleLoading || !articleData?.article.canMakeDeleteArticleRequest}
                    onClick={() => deleteArticleRequest({variables: {id: articleId}})}
                >
                    {articleData?.article.canMakeDeleteArticleRequest
                        ? "Request to delete"
                        : "Article pending deletion"
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
};
