import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";

import { ApolloLink, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { config } from "../../config";

//@ts-ignore
export const httpLink: ApolloLink = createUploadLink({
    uri: config.httpLink,
    credentials: "include"
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
    uri: config.wsLink,
    options: {
        lazy: true,
        reconnect: true,
    }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
export const link = split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    //@ts-ignore
    wsLink,
    httpLink,
);