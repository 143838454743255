import React, { FunctionComponent, useEffect, useMemo } from "react";

import {
    Avatar, Card, CardContent, IconButton, List, ListItem, ListItemIcon,
    ListItemSecondaryAction, ListItemText, Switch, Typography
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import EmailIcon from "@material-ui/icons/Email";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";

import { Header, Page, useLoadingBarStore, useSnackbarStore } from "../../components";
import {
    useProfileQuery, useUpdateNotificationSettingsMutation, NotificationSettingsType, RoleEnum} from "../../generated/generated-types";
import getAccountId from "../../utils/getAccountId";
import { useHistory } from "react-router";
import moment from "moment";
import getRole from "../../utils/getRole";

export const ProfileScreen: FunctionComponent = () => {
    const lastMonth = useMemo(() => moment().subtract(1, "month"), []);

    const { data, refetch } = useProfileQuery({ variables: { id: getAccountId(), lastMonth: lastMonth.toString() } });

    const fullName = `${data?.accountById?.firstName} ${data?.accountById?.lastName}`;

    const [updateNotificationSettings, { loading: updateNotificationSettingsLoading }] = useUpdateNotificationSettingsMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: () => {
            refetch();
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(updateNotificationSettingsLoading);
    }, [updateNotificationSettingsLoading]);

    const history = useHistory();

    const showPublished = useMemo(() => {
        const role = getRole();

        return role === RoleEnum.Qa || role === RoleEnum.Admin;
    }, []);

    return (
        <Page
            header={
                <Header title={"My Profile"} />
            }
        >
            <div style={{marginBottom: 32, display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Avatar
                    alt={fullName}
                    style={{ width: 128, height: 128, marginBottom: 16 }}
                    src={data?.accountById?.firstName}
                />
                <Typography variant={"h5"}>
                    Welcome, {fullName}
                </Typography>
                <Typography>
                    Manage your account, notifications and security settings here
                </Typography>
            </div>

            <Card elevation={0} style={{border: "1px solid #dadce0", marginBottom: 16}}>
                <CardContent>
                    <Typography style={{fontSize: 24}}>
                        Statistics
                    </Typography>
                </CardContent>
                <List>
                    <StatisticListItem
                        prefix={"TOTAL CONTRIBUTIONS"}
                        value={`${data?.contributionsCount}`}
                    />
                    <StatisticListItem
                        prefix={"MONTHLY CONTRIBUTIONS"}
                        value={`${data?.monthContributionsCount}`}
                    />
                    {showPublished && <StatisticListItem
                        prefix={"TOTAL PUBLISHED"}
                        value={`${data?.publishedCount}`}
                    />}
                    {showPublished && <StatisticListItem
                        prefix={"MONTHLY PUBLISHED"}
                        value={`${data?.monthPublishedCount}`}
                    />}
                </List>
            </Card>
            <Card elevation={0} style={{border: "1px solid #dadce0", marginBottom: 16}}>
                <CardContent>
                    <Typography style={{fontSize: 24}}>
                        Profile
                    </Typography>
                </CardContent>
                <List>
                    <ProfileListItem
                        prefix={"EMAIL"}
                        value={`${data?.accountById?.email}`}
                        onClick={() => {}}
                    />
                    <ProfileListItem
                        prefix={"NAME"}
                        value={fullName}
                        onClick={() => {}}
                    />
                    <ProfileListItem
                        prefix={"PASSWORD"}
                        value={"*********"}
                        onClick={() => history.push({pathname: "/profile/update-password", state: { background: "/profile" }})}
                    />
                </List>
            </Card>
            <Card elevation={0} style={{border: "1px solid #dadce0", marginBottom: 32}}>
                <CardContent>
                    <Typography style={{fontSize: 24}}>
                        Notifications
                    </Typography>
                </CardContent>
                <List>
                    <NotificationListItem
                        icon={<EmailIcon />}
                        value={"Email Notifications"}
                        onChange={(enabled) => updateNotificationSettings({
                            variables: {
                                type: NotificationSettingsType.Email,
                                enabled
                            }
                        })}
                        enabled={data?.accountById?.notificationSettings?.email}
                    />
                    <NotificationListItem
                        icon={<LaptopMacIcon />}
                        value={"In App Notifications"}
                        onChange={(enabled) => updateNotificationSettings({
                            variables: {
                                type: NotificationSettingsType.Inapp,
                                enabled
                            }
                        })}
                        enabled={data?.accountById?.notificationSettings?.inApp}
                        description={"If enabled, will continue to recieve in app notifications even while offline"}
                    />
                    <NotificationListItem
                        icon={<DynamicFeedIcon />}
                        value={"Real-Time Notifications"}
                        enabled={data?.accountById?.notificationSettings?.realTime}
                        description={"If enabled, will show real time overlay notifications"}
                        onChange={(enabled) => updateNotificationSettings({
                            variables: {
                                type: NotificationSettingsType.Realtime,
                                enabled
                            }
                        })}
                    />
                </List>
            </Card>
        </Page>
    );
};

const StatisticListItem: React.FC<{prefix: string, value: string}> = (props) => {
    const { prefix, value } = props;

    return (
        <ListItem
            style={{paddingLeft: 16, paddingRight: 16, paddingBottom: 8, paddingTop: 8}}
        >
            <ListItemIcon style={{minWidth: 180}}>
                <Typography style={{fontSize: 12}}>
                    {prefix}
                </Typography>
            </ListItemIcon>
            <ListItemText primary={value} />
        </ListItem>
    );
};

const ProfileListItem: React.FC<{prefix: string, value: string, onClick(): void}> = (props) => {
    const { prefix, value, onClick } = props;

    return (
        <ListItem
            button
            onClick={onClick}
            style={{paddingLeft: 16, paddingRight: 16, paddingBottom: 8, paddingTop: 8}}
        >
            <ListItemIcon style={{minWidth: 180}}>
                <Typography style={{fontSize: 12}}>
                    {prefix}
                </Typography>
            </ListItemIcon>
            <ListItemText primary={value} />
            <ListItemSecondaryAction>
                <IconButton>
                    <ArrowForwardIosIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

interface NotificationListItemProps {
    icon: JSX.Element;
    enabled?: boolean;
    description?: string;
    value: string;
    onChange(enabled: boolean): void;
}

const NotificationListItem: React.FC<NotificationListItemProps> = (props) => {
    const { icon, description, value, onChange, enabled } = props;

    return (
        <ListItem
            style={{paddingLeft: 16, paddingRight: 16, paddingBottom: 8, paddingTop: 8}}
        >
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={value} secondary={description} />
            <ListItemSecondaryAction>
                {enabled !== undefined && <Switch
                    edge={"end"}
                    onChange={(_e, checked) => onChange(checked)}
                    checked={enabled}
                />}
            </ListItemSecondaryAction>
        </ListItem>
    );
};
