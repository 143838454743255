import React, { useCallback, useEffect } from "react";

import { Button } from "@material-ui/core";

import { useLoadingBarStore, useSnackbarStore } from "../../..";
import { useSubmissionsQuery, useUnflagDraftMutation } from "../../../../generated/generated-types";

export const UnflagChangesButton: React.FC<{ draftId?: string }> = (props) => {
    const { draftId } = props;

    const { refetch: refetchSubmission } = useSubmissionsQuery();

    const [unflagDraft, { loading: unflagDraftLoading }] = useUnflagDraftMutation({
        onError: (err) => {
            setSnackbarMessage(err.message);
        },
        onCompleted: async () => {
            await refetchSubmission();
        }
    });

    const setSnackbarMessage = useSnackbarStore((state) => state.setSnackbarMessage);
    const setLoadingStore = useLoadingBarStore((state) => state.setLoadingState);
    useEffect(() => {
        setLoadingStore(unflagDraftLoading);
    }, [unflagDraftLoading, setLoadingStore]);

    const unflag = useCallback(
        () => {
            if (draftId) {
                unflagDraft({variables: { id: draftId}});
            }
        },
        [unflagDraft, draftId],
    );

    return (
        <Button
            color={"primary"}
            style={{ marginRight: 8 }}
            disabled={typeof draftId === "undefined" || unflagDraftLoading}
            onClick={unflag}
        >
            Unflag Article
        </Button>
    );
};