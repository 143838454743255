if (!process.env.REACT_APP_HTTP_LINK) {
    throw Error("No http link defined");
}

if (!process.env.REACT_APP_WS_LINK) {
    throw Error("No ws link defined");
}

interface Config {
    httpLink: string;
    wsLink: string;
}

export const config: Config = {
    httpLink: new URL("graphql", process.env.REACT_APP_HTTP_LINK).href,
    wsLink: new URL("graphql", process.env.REACT_APP_WS_LINK).href,
};
