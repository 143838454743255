import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        overflow: "auto",
    },
    content: {
        padding: theme.spacing(2),
        minHeight: 200,
    },
    selectHubHeader: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(0.5),
        color: "#757575"
    }
}));
